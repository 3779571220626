.startLearningWrapper{
    text-align: center;
    background:  url("../../../assets/images/blogPosts/texturebg.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    .headTag{
        font-size: 32px;
        color: #FFFFFF;
        font-weight: 700;
        font-family: "Outfit", sans-serif;
        word-wrap: break-word;
    }
    .subTag{
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 300;
        font-family: "Outfit", sans-serif;
        word-wrap: break-word;
    }
    .startLearningBtn{
        color: #1C1917;
        background-color: #FFFFFF;
        text-transform: none;
        
        padding: 8px 18px;
        font-weight: 500;
        font-family: "Outfit", sans-serif;
        word-wrap: break-word;
        line-height: 20px;
    }
    .MuiButton-root:hover{
        background-color: #a9a9a9;
    }
}