.red {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .round {
    width: 13px;
    height: 13px;
    background-color: rgb(255, 206, 206); /* Assuming this is the red background you referred to */
    border-radius: 50%;
    position: absolute;
    z-index: 1; /* Lower z-index to stay behind the icon */
    margin-left: 0.5px;
  }
  
  .Mic {
    width: 10px;
    position: absolute;
    z-index: 2; /* Higher z-index to appear above the red round element */
  }