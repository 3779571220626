.mentorsContainer{
  background-color: #faf7ff;
  .title{
    font-family: "Outfit", sans-serif;
    word-wrap: break-word;
    font-weight: 500;
    text-align: center;
  }
  .sliderContainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
  
  .mentorCard{
    width: 272px;
    background-color: #ffffff;
    border-radius: 24px;
    max-width: 293px;
    padding: 16px 16px 24px 16px ;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    .name{
      font-family: "Outfit", sans-serif;
      word-wrap: break-word;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 600;
      color: #000000;
    }
    .designation{
      font-family: "Outfit", sans-serif;
      word-wrap: break-word;
      font-size: 14px;
      font-weight: 400;
      color: #8E8E8E;
    }
    .linkedinLink{
      text-decoration: none;
      color: #007AB9; 
       font-family: "Outfit", sans-serif;
      word-wrap: break-word;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
    }
  }
  .profimg{
    width: 100%;
    border-radius: 8px;
    height: 240px;
  }
}
} 
