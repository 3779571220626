.coursesSection {
  padding: 33px 60px 71px 60px;
  text-align: center;
  background-color: #f3f4fd;

  .headerSection {
    .headText {
      color: #ff7262;
      font-family: "Outfit", sans-serif;
      font-weight: 700;
      font-size: 32px;
      word-wrap: break-word;
    }

    .subText {
      color: #0f002e;
      font-family: "Outfit", sans-serif;
      font-size: 36px;
      font-weight: 400;
      word-wrap: break-word;
    }
  }

  .levelsWrapper {
    border-radius: 16px;

    .buttonsWrapper {
      display: flex;
      width: 100%;
      justify-content: center;

      .levelBtn {
        width: 100%;
        text-transform: none;
        padding: 16px 0px;
        border-radius: 0px;
        color: #000000;
        font-size: 24px;
        font-family: "Outfit", sans-serif;
        font-weight: 600;
      }

      .active {
        background-color: #0f002e;
        color: white;
      }
    }

    .courseCards {
      text-align: left;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 26px;
    }
  }
}

.courseCardWrapper {
  border-radius: 12px;
  width: 300px;
  flex-shrink: 0;
  overflow: hidden;

  .imageContainer {
    width: 100%;
    height: 154px;
  }

  .courseContainer {
    padding: 13px 18px 9px 18px;

    .placementTag {
      color: #7234f7;
      font-size: 14px;
      font-family: "Outfit", sans-serif;
      font-weight: 400;
      word-wrap: break-word;
    }

    .courseName {
      color: #000000;
      font-family: "Outfit", sans-serif;
      font-size: 16;
      font-weight: 700;
      word-wrap: break-word;
    }

    .listContent {
      display: flex;
      padding-bottom: 10px;
      gap: 10px;

      .label {
        color: #000000;
        font-family: "Outfit", sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        word-wrap: break-word;
      }
    }
  }

  .buttonContainer {
    .button {
      width: 50%;
      text-transform: none;
      border-radius: 0px;
      color: #ffffff;
      padding: 18px 10px;
    }
  }
}

@media (max-width: 768px) {
  .courseCardWrapper {
    width: 100%;
  }

  .courseCards {
    justify-content: center;
  }
  @media (max-width: 900px) {
    .levelBtn {
      font-size: 14px !important;
      font-weight: 600 !important;
    }

    .headText,
    .subText {
      font-size: 20px !important;
    }

    .headerSection {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 600px) {
    .hide-icon {
      display: none;
    }

    .courseCards {
      gap: 16px !important;
    }
  }
}
