.rolesSection {
    text-align: center;
    position: relative;
    .headerSubtext,
    .dsLabel,
    .txtHighlight {
      font-family: "Outfit", sans-serif;
      line-height: 1.5;
      font-weight: 400;
    }
  
    .headerTitle {
      padding-bottom: 9px;
    }
  
    .headerSubtext {
      margin: auto;
      color: #000000;
    }
  
    .sliderContainer {
      display: flex;
      overflow: hidden;
      position: relative;
      width: 100%;
      white-space: nowrap;
  
      &:hover .sliderContent {
        animation-play-state: paused;
      }
  
      .sliderContent {
        display: flex;
        gap: 20px;
        animation: rolesScroll 30s linear infinite;
        white-space: nowrap; 
      }
  
      @keyframes rolesScroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-50%); 
        }
      }
  
      .txtHighlight {
        color: #fe7465;
        font-size: 16px;
        font-weight: 700;
      }
  
      .rolesCard {
        border-radius: 12px;
        background-color: #ffffff;
        min-width: 326px;
        padding: 16px 12px 16px 28px;
        flex-shrink: 0; 
        position: relative;
  
        .role {
          color: #4f4f4f;
          font-family: "Outfit", sans-serif;
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 22px;
          text-align: left;
          line-height: 1.5;
        }
  
        .subPoints {
          display: flex;
          gap: 6px;
          padding-bottom: 8px;
  
          .salary,
          .openings {
            font-size: 14px;
            font-weight: 300px;
            font-family: "Outfit", sans-serif;
            color: #4f4f4f;
            line-height: 1.5;
          }
        }
      }
    }
  
    .srcWrapper {
      display: flex;
      justify-content: center;
      padding: auto 16px;
      width: 100%;
      gap: 20px;
    }
  
    .ambBtn,
    .linkBtn {
      height: 48px;
      border-radius: 6px;
    }
  
    .ambBtn {
      padding: 16px 37px;
      margin-left: 16px;
      background: rgba(86, 112, 251, 0.1);
    }
  
    .linkBtn {
      padding: 13px 57px;
      margin-right: 16px;
      background: rgba(10, 102, 194, 0.1);
    }
  }
  