.project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100% !important;
  // width: 381px !important;
  height: 370px;
  flex-shrink: 0;
  padding: 16px;
  margin: 16px;
  border-radius: 12px !important;
  background-color: #fff;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .chip-category {
      font-size: 12px;
      font-weight: 400;
      background-color: rgba(114, 52, 247, 0.04);
      color: #7234F7;
      text-align: right;
      font-family: Poppins;
      font-style: normal;
      line-height: normal;
    }

    .level-box {
      display: inline-flex;
      align-items: center;
      gap: var(--radius-radius-md, 8px);
    }

    .chip-level {
      color: #1C1917;
      font-family: Outfit;
      background-color: white;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .title-box {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-shrink: 0;
    margin-bottom: 10px; // Adjust spacing if needed
  }
  
  .title {
    color: #1C1917;
    font-family: Outfit, sans-serif;
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4; /* Adjust line height for better spacing */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    margin-bottom: 0; /* Prevent cutting */
  }
  

  .description-box{
    margin-top: 10px;
  }
  .description {
    color: #292524;
    text-align: left;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
  }

  .tool-head{
    margin-top: 20px;
    color: #1C1917;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .tools {
    margin-bottom: 16px;
    display: flex;
    padding-top: 6px;
    gap: 6px;

    .tool-chip {
      display: flex;
      max-width: 69px;
      border-radius: 80px;
      align-items: center;
      background: #F2F2FD;
      color: #1C1917;
      font-family: Poppins;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .users {
    display: inline-flex;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
    gap: var(--radius-radius-md, 8px);
    .user-avatar{
      border-radius: 45px;
      width: 28px;
      height: 28px;
    }
    .joined-text {
      width: 82px;
      height: 16px;
      color: var(--Neautral-900, #1C1917);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }


  .view-button-box {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-grow: 1;
    flex-direction: column; 
  }
  
  .view-project-btn {
    display: flex;
    width: 100%;
    padding: 8px 20px;
    flex-direction: column;
    align-items: center;
    border-radius: var(--radius-radius-md, 8px);
    border: 1px solid #7234F7;
    background: none;
    color: #7234F7;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 10px;
  
    &:hover {
      background-color: #E0E0F8;
    }
  }
  @media (max-width: 600px) {
    height: auto; // Adjust height to allow content to grow based on screen size
    padding: 12px; // Reduce padding for mobile

    .header {
      flex-direction: column; // Stack header elements for smaller screens
      align-items: flex-start;
      margin-bottom: 12px;
    }

    .chip-category {
      font-size: 10px; // Smaller font size for mobile
      margin-bottom: 8px; // Add some space between elements
    }

    .level-box {
      justify-content: flex-start; // Align items to the start on mobile
      margin-top: 8px;
    }

    .title {
      font-size: 18px; // Adjust font size for smaller screens
    }

    .description {
      font-size: 12px; // Adjust description font size for smaller screens
    }

    .tool-head {
      font-size: 14px; // Adjust tools heading size for mobile
    }

    .tool-chip {
      font-size: 10px; // Make tool chips smaller on mobile
      padding: 4px 8px; // Adjust padding for smaller screens
    }

    .view-project-btn {
      width: 100%; // Full width button on mobile
      font-size: 12px; // Smaller font size for the button
    }
  }
   
}

@keyframes flicker {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}

.flicker-skeleton {
  animation: flicker 1.5s infinite ease-in-out;
}
.description {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}








