.contentBox-question-asses {
  width: 75vw !important;
  height: 77.5vh !important;
  border-radius: 32px !important;
  background: rgba(115, 115, 133, 0.26) !important;
  backdrop-filter: blur(80.25px) !important;
  display: flex;
  flex-direction: column;
}

.progressBar-question-asses {
  padding: 10px;
  display: flex !important;
  justify-content: center !important;
  align-items: start !important;
  position: relative !important;
}

.progressBar-1-asses-unfilled {
  width: calc(100% - 24px);
  height: 1.5px;
  background: #cbb1ff;
  position: absolute;
  top: 12px;
  // left: 24px;
  left: calc(50% + 12px);
}

.progressBar-1-asses-filled {
  width: calc((100% - 24px) / 9 * var(--selected-count));
  // width: 100%;
  height: 1.5px;
  background: #7233f7;
  position: absolute;
  top: 12px;
  left: calc(50% + 12px);
}

.progressBar-2-asses-unfilled {
  width: calc(100% - 24px);
  height: 1.5px;
  background: #cbb1ff;
  position: absolute;
  top: 12px;
  // left: 24px;
  left: calc(50% + 12px);
}

.progressBar-2-asses-filled {
  width: calc((100% - 24px) / 5 * var(--selected-count));
  // width: 100%;
  height: 1.5px;
  background: #7233f7;
  position: absolute;
  top: 12px;
  left: calc(50% + 12px);
}

.text-question-asses {
  color: #fff !important;
  font-family: Outfit !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  margin: 13px 48px 5px 48px !important;
  padding-bottom: 20px !important;
  margin-left: 10px;
  padding-left: 30px;
  padding-top: 20px;
}

.gradient-border-question-asses {
  width: 420px;
  height: 58px;
  background: linear-gradient(90deg, #ffffff4b, #9b4eff);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin-left: 80px;
  margin-bottom: 10px;
  overflow: clip;
  padding: 1px;
}

.gradient-border-question-asses:hover {
  cursor: pointer !important;
}

.question-asses {
  border-radius: 6px !important;
  background: #0f012d !important;
  backdrop-filter: blur(24.450000762939453px) !important;
  color: #fff !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 12px 10px;
 
}

.tick-question-asses {
  display: block !important;
}

.box-asses {
  border-radius: 6px;
  backdrop-filter: blur(24.450000762939453px);
  padding: 10px;
  line-height: 10px;
  border-radius: 4px;
  border: 1px solid #fff;
  background: rgba(114, 51, 247, 0.2);
}

.prevBtn-question-asses {
  border-radius: var(--radius-radius-md, 8px) !important;
  border: 1px solid #fff !important;
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 23px !important;
  font-style: normal !important;
  font-weight: 200 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  width: 180px !important;
  height: 50px !important;
}

.nextBtn-question-asses {
  border-radius: var(--radius-radius-md, 8px);
  background: var(--kll, linear-gradient(90deg, #7233f7 0%, #4400d2 100%));
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: none !important;
  width: 180px !important;
  height: 50px !important;
}

.overlay-question-asses {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  background: rgba(255, 255, 255, 0.18) !important;
}

.text-1-result1 {
  color: #fff !important;
  font-family: Outfit !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.text-2-result1 {
  color: #fff !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.btn-result1 {
  color: #fff !important;
  font-family: Outfit !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: none !important;
  border-radius: var(--radius-radius-md, 8px) !important;
  background: linear-gradient(90deg, #7234f7 0%, #fe7465 126.33%) !important;
  padding: 12px 40px !important;
}

.text-3-result1 {
  color: #fff !important;
  font-family: Outfit !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.unfilled-message-question {
  border-radius: 6px !important;
  background: #fffcfc !important;
  color: #d90000 !important;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding: 3px 2px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 285px !important;
  margin-left: 80px !important;
  margin-bottom: 5px !important;
}

.text-1-result2 {
  color: #fff !important;
  font-family: Outfit !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.text-2-result2 {
  color: #fff !important;
  font-family: Outfit !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.text-3-result2 {
  color: #fff !important;
  font-family: Outfit !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.text-4-result2 {
  color: #fff !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.text-5-result2 {
  color: #fff !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.text-6-result2 {
  font-family: Outfit !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  background: linear-gradient(90deg, #7233f7 0%, #ff7262 98.22%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.text-7-result2 {
  color: var(--Filled, #000) !important;
  font-family: Outfit !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.text-8-result2 {
  color: var(--Filled, #000) !important;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.progressBar-finalResult-unfilled {
  position: relative;
  width: 224px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50px;
  top: 4px;
}

.progressBar-finalResult-filled {
  position: relative;
  width: calc(224px * var(--percentage) / 100);
  height: 8px;
  border-radius: 50px;
  background: linear-gradient(270deg, #f88282, #7234f7);
  top: -4px;
}

.btn-result2 {
  color: #7233f7 !important;
  font-family: Poppins !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  border-radius: 3px;
  border: 0.5px solid #7233f7 !important;
  padding: 5px 16px;
}

.slider-result2 {
  width: 100% !important;
  overflow: hidden;
  display: flex !important;
  padding: 0 20px;
}

.innerSlider-result2 {
  display: flex !important;
  width: 100% !important;
  // padding-right: 20px;
  gap: 20px;
}

.card-wrapper-result2 {
  background-color: white;
  width: 300px !important;
  height: 124px !important;
  border-radius: 8px;
  border: 0.5px solid #9f9f9f;
  background: #fff;
  backdrop-filter: blur(52px);
  padding: 10px;
  margin-top: 10px;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
  cursor: pointer !important;
}

.questionText {
  color: #fff !important;
  text-align: start !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

@media (max-width: 900px) {
  .contentBox-question-asses {
    background-color: transparent !important;
    width: 95vw !important;
    height: 60vh !important;
  }

  .gradient-border-question-asses {
    margin-left: 0px !important;
    height: 48px !important;
    width: 318px !important;
  }
  .text-question-asses {
    margin: 10px 0px 5px 0px !important;
    line-height: 16px !important;
  }
  .unfilled-message-question {
    margin-left: 0px !important;
  }
  .questionText {
    font-size: 12px !important;
  }
  .box-asses {
    padding: 5px !important;
  }
  .text-1-result1 {
    font-size: 24px !important;
    text-align: center !important;
  }
  .text-2-result1 {
    font-size: 12px !important;
  }
  .text-2-result2 {
    font-size: 14px !important;
  }
  .text-3-result2 {
    font-size: 14px !important;
  }
  .progressBar-finalResult-filled {
    width: calc(100px * var(--percentage) / 100);
  }
  .progressBar-finalResult-unfilled {
    width: 100px;
  }
  .text-4-result2 {
    font-size: 16px !important;
  }
  .text-5-result2 {
    font-size: 12px !important;
  }
}


