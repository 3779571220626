.head-text-key {
  color: #fe7465 !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  margin-bottom: 56px !important;
}

.wrap-key {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Creates flexible columns */
  gap: 50px; /* Consistent gap between rows and columns */
  justify-content: left; /* Centers the grid horizontally */
  padding: 20px; /* Padding around the grid */
  max-width: 1200px; /* Maximum width for the grid */
  margin: 0 auto; /* Centers the grid on the page */

}

.wrap-key-inside {
  display: flex;
  align-items: left; /* Centers items vertically */
  justify-content: left; /* Centers items horizontally */
  border-radius: 10px;
  border: 1px solid #7234f76b;
  padding: 20px; /* Padding inside each box */
  text-align: left; /* Centers text within the box */
  box-sizing: border-box;
  background-color: transparent; /* Optional: Adds a light background color */
}

.wrap-key-inside img {
  margin-right: 3px;
  width: 20px; /* Set a smaller fixed width for icons */
  height: 25px; /* Set a smaller fixed height for icons */
  gap: 10px; /* Adds space between the icon and text */
}

.norma-text-key {
  color: var(--Filled, #000) !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-align: center; /* Ensures text is centered */
}

.wi-1,
.wi-2 {
  justify-content: center !important; /* Centers content for these classes */
}

.w1,
.w2 {
  width: auto;
  max-width: 40vw; /* Maximum width for larger screens */
  min-width: 300px; /* Minimum width for smaller screens */
}

@media (max-width: 900px) {
  .header-text-key {
    font-size: 24px !important; /* Adjusts font size for smaller screens */
  }

  .wrap-key {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust columns for smaller screens */
    gap: 15px; /* Reduce gap for smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
  }
  .wrap-key-inside {
    padding: 20px; /* Adjust padding for smaller screens */
  }

  .w1,
  .w2 {
    width: 90vw; /* Full width on smaller screens */
    max-width: none; /* Removes max-width restriction */
    min-width: auto; /* Removes min-width restriction */
  }
}
