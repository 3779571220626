.slider-wrapper-asses {
  width: 8px;
  height: 65%;
  background-color: white;
  position: absolute;
  right: 30px;
  top: 55px;
  border-radius: 50px;
  padding: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //   align-items: center;
  z-index: 10;
  // margin: 10px 50px;
}
.slider-thumb-asses:hover {
  cursor: pointer;
}

.slider-thumb-asses {
  width: 6px;
  height: calc(100% / var(--totalQuestion));
  border-radius: 50px;
}

.active-slider-asses {
  background-color: #0f012d;
}

@media (max-width: 900px) {
  .slider-wrapper-asses {
    right: 0px !important;
    top: 40px !important;
  }
}
