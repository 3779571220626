.topWrapper-landing-asses {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, rgb(0, 0, 0) -11.67%, #1c0058 142.19%);
}

.logo-landing-asses {
  position: absolute;
  padding: 20px 30px;
}

.contentBox-landing-asses {
  width: 75vw;
  height: 55vh;
  border-radius: 32px;
  background: rgba(115, 115, 133, 0.26);
  backdrop-filter: blur(80.25px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-contentBox-landing-asses {
  color: #fff;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  margin-top: 20px !important;
}

.text-contentBox-landing-asses-2 {
  background: linear-gradient(89deg, #bd89ff 30.21%, #ff8f9b 70.04%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.text-contentBox-landing-asses-3 {
  color: #fff !important;
  font-family: Outfit !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-align: center !important;
  margin-top: 30px !important;
}
.btn-contentBox-landing-asses {
  border-radius: var(--radius-radius-md, 8px);
  background: linear-gradient(90deg, #7234f7 0%, #fe7465 126.33%);
  display: flex;
  width: 188px;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  text-transform: none !important;
}

.text-contentBox-landing-asses-4 {
  color: #fff;
  font-family: Outfit !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.text-contentBox-landing-asses-5 {
  position: absolute !important;
  color: #fff !important;
  font-family: Outfit !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 100 !important;
  line-height: normal !important;
  bottom: 10px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

@media (max-width: 900px) {
  .logo-landing-asses {
    padding: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .text-contentBox-landing-asses {
    font-size: 20px !important;
  }
  .text-contentBox-landing-asses-2 {
    font-size: 20px !important;
  }
  .text-contentBox-landing-asses-3 {
    font-size: 14px !important;
  }
  .btn-contentBox-landing-asses {
    font-size: 16px !important;
  }
  .text-contentBox-landing-asses-4 {
    font-size: 14px !important;
  }
  .contentBox-landing-asses {
    padding: 16px;
  }
}
