.form {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.form-label {
    display: block;
    margin-bottom: 10px;
}

.form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
}

.form-error {
    color: red;
    margin-top: -10px;
    margin-bottom: 10px;
}

.form-submit {
    padding: 10px 20px;
    background-color: #3094ED;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.paymentForm {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
}