
// .corePoints {
//   .frameWrapper {
//     display: flex;
//     justify-content: space-between;
//     padding: 33px 50px;
//     border-radius: 16px;
//     background: #FFFFFF;

//     .sectionWrapper {
//       display: flex;
//       justify-content: space-evenly;
//       align-items: center;

//       .section {
//         display: flex;
//         align-items: center;
//         padding: 10px;

//         .subtitle1 {
//           font-family: "Outfit", sans-serif;
//           word-wrap: break-word;
//           font-size: 20px;
//           font-weight: 400;
//           line-height: 1;
//         }

//         .subtitle2 {
//           font-family: "Outfit", sans-serif;
//           word-wrap: break-word;
//           font-size: 22px;
//           font-weight: 500;
//           line-height: 1;
//         }
        
//         // Adjust the icon size
//         img {
//           width: 56px; // Increase the default size slightly
//           height: auto; // Keep auto to maintain aspect ratio
//         }
//       }
//     }
//   }

//   @media (max-width: 900px) {
//     .verticalIcon {
//       display: none;
//     }
    
//     .sectionWrapper {
//       justify-content: flex-start !important;
//     }

//     .frameWrapper {
//       padding: 32px !important;
//     }

//     .subtitle1 {
//       font-size: 12px !important;
//     }

//     .subtitle2 {
//       font-size: 14px !important;
//     }

//     .section img {
//       width: 40px !important; // Smaller size for mobile view
//     }
//   }
// }
.corePoints {
  .frameWrapper {
    display: flex;
    justify-content: space-between;
    padding: 33px 50px;
    border-radius: 16px;
    background: #FFFFFF;

    .sectionWrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .section {
        display: flex;
        align-items: center;
        padding: 10px;

        .subtitle1 {
          font-family: "Outfit", sans-serif;
          word-wrap: break-word;
          font-size: 20px;
          font-weight: 400;
          line-height: 1;
        }

        .subtitle2 {
          font-family: "Outfit", sans-serif;
          word-wrap: break-word;
          font-size: 22px;
          font-weight: 500;
          line-height: 1;
        }
        
        // Adjust the icon size
        img {
          width: 56px; // Increase the default size slightly
          height: auto; // Keep auto to maintain aspect ratio
        }
      }
    }
  }

  @media (max-width: 900px) {
    .verticalIcon {
      display: none;
    }
    
    .sectionWrapper {
      justify-content: flex-start !important;
    }

    .frameWrapper {
      padding: 32px !important;
    }

    .subtitle1,
    .subtitle2 {
      font-size: 14px !important;
      white-space: nowrap !important; // Prevent word breaking
      overflow: hidden; // Hide overflow if text exceeds container
      text-overflow: ellipsis; 
      // Add ellipsis for truncated text
    }

    .section img {
      width: 40px !important; // Smaller size for mobile view
    }
    .section{
      margin-left: -10px !important;
    }
  }
}
