.footerWrapper {
  .footerWrapperSections {
    display: flex;
    justify-content: space-between;
  }

  .footerText {
    color: #100031;
    font-family: "Outfit", sans-serif;
    font-style: normal;
    line-height: normal;

    &.footerHeaderText {
      margin-top: 0;
      font-weight: 600;
      padding-bottom: 18px;
    }

    &.companyText {
      display: flex;
      flex-direction: column;
      gap: 16px;
      font-size: 16px;
    }

    &.copyright {
      padding-right: 10px;
      font-size: 12px;
      margin-top: 0;
    }
  }

  .innerText {
    text-decoration: none;
    color: #100031;
    padding: 5px 0;
    cursor: pointer;
  }

  .footerSection {
    .socialMedia {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0;
    }

    .socialAcc {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding-top: 4px;
      border-radius: 50%;
      background-color: #7233f7;
    }
  }

  .addressText {
    font-size: 16px;
  }

  .footerDetails {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .contact {
      .email,
      .call {
        width: 30px;
        height: 30px;
      }
    }

    &.contact {
      gap: 10px !important;
      margin-top: 0;
      font-size: 14px !important;
      font-family: "Outfit", sans-serif;
      padding-right: 50px;
    }
  }

  
  @media (max-width: 900px) {
    .footerHeaderText {
      padding-bottom: 6px !important;
      font-size: 16px;
    }

    .companyText {
      gap: 0px !important;
      line-height: 23px;
    }

    .socialMedia {
      gap: 8px;
    }

    .contact {
      padding-right: 5px !important;
      gap: 3px !important;
    }
  }

  @media (max-width: 600px) {

    .companyText,
    .companyText .innerText {
      font-size: 14px !important;
    }

    .copyright,
    .footerDetails .contact {
      font-size: 10px !important;
    }

    .contact {

      .email,
      .call {
        width: 24px;
        height: 24px;
      }
    }

    .copyright {
      padding-top: 20px;
    }
  }
}