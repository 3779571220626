// HeroSection.scss
.home-page {
  background-color: #000; 
}


//navbar
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
  backdrop-filter: blur(10px); // Blur effect
  -webkit-backdrop-filter: blur(10px); // For Safari support
  z-index: 1000;

  .navbar-logo {
    img {
      height: 30px; // Adjust logo size
      width: auto;
      margin-left: 55px;
      transition: transform 0.3s ease;

      // &:hover {
      //   transform: scale(1.1);
      // }

    }
  }
}


// Responsive styling
@media (max-width: 768px) {
  .navbar {
    padding: 15px;
    position: relative;

    .navbar-logo img {
      height: 20px;
      margin-left: 0px;
    }
  }
}


.hero-section {
    display: flex;
    align-items: center;
    padding-left: 50px;
    background-color: #000;
    margin-left: 10px;
    gap: 180px;
    padding-top: 60px;
  
    .hero-content {
      color: #fff;
      max-width: 600px;
      margin-left: 20px;
      font-family: poppins;
      
      h1 {
        font-size: 2.7rem;
        line-height: 1.2;
        margin-bottom: 10px;
  
        span {
          color: #BD89Ff;
        }
      }
  
      p {
        font-size: 1.2rem;
        margin-bottom:-14px;
        color: #aaa;
      }
  
      .cta-button {
        margin-top: 50px;
        position: relative;
        padding: 12px 25px;
        background-color: #7234fe;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all .3s ease-in-out;
        cursor: pointer;
      }
      .star-1 {
        position: absolute;
        top: 20%;
        left: 10%;
        width: 30px;
        height: auto;
        filter: drop-shadow(0 0 0 #7234fe);
        z-index: -5;
        transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
      }
      .star-2 {
        position: absolute;
        top: 45%;
        left: 35%;
        width: 20px;
        height: auto;
        filter: drop-shadow(0 0 0 #7234fe);
        z-index: -5;
        transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
      }
      .star-3 {
        position: absolute;
        top: 40%;
        left: 30%;
        width: 10px;
        height: auto;
        filter: drop-shadow(0 0 0 #7234fe);
        z-index: -5;
        transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
      }
      .star-4 {
        position: absolute;
        top: 20%;
        left: 30%;
        width: 13px;
        height: auto;
        filter: drop-shadow(0 0 0 #7234fe);
        z-index: -5;
        transition: all .8s cubic-bezier(0, 0.4, 0, 1.01);
      }
      .star-5 {
        position: absolute;
        top: 25%;
        left: 35%;
        width: 20px;
        height: auto;
        filter: drop-shadow(0 0 0 #7234fe);
        z-index: -5;
        transition: all .6s cubic-bezier(0, 0.4, 0, 1.01);
      }
      .star-6 {
        position: absolute;
        top: 5%;
        left: 40%;
        width: 10px;
        height: auto;
        filter: drop-shadow(0 0 0 #7234fe);
        z-index: -5;
        transition: all .8s ease;
      }
      button:hover {
        background: white;
        color:#7234fe;
        box-shadow: 0 0 25px #7234fe;
      }
      button:hover .star-1 {
        position: absolute;
        top: -80%;
        left: -10%;
        width: 30px;
        height: auto;
        filter: drop-shadow(0 0 10px #7234fe);
        z-index: 2;
      }
      button:hover .star-2 {
        position: absolute;
        top: -25%;
        left: 20%;
        width: 20px;
        height: auto;
        filter: drop-shadow(0 0 10px #7234fe);
        z-index: 2;
      }
      button:hover .star-3 {
        position: absolute;
        top: 55%;
        left: 15%;
        width: 10px;
        height: auto;
        filter: drop-shadow(0 0 10px #7234fe);
        z-index: 2;
      }
      button:hover .stars {
        display: block;
        filter: drop-shadow(0 0 10px #7234fe);
      }
      button:hover .star-4 {
        position: absolute;
        top: 30%;
        left: 80%;
        width: 13px;
        height: auto;
        filter: drop-shadow(0 0 10px #7234fe);
        z-index: 2;
      }
      button:hover .star-5 {
        position: absolute;
        top: 25%;
        left: 105%;
        width: 20px;
        height: auto;
        filter: drop-shadow(0 0 10px #7234fe);
        z-index: 2;
      }
      button:hover .star-6 {
        position: absolute;
        top: 5%;
        left: 50%;
        width: 10px;
        height: auto;
        filter: drop-shadow(0 0 10px #7234fe);
        z-index: 2;
      }
      .fil0 {
        fill: #7234fe
      }
      
    }
  
    .hero-image {
      position: relative;
      display: inline-block;
      width: 500px; // Adjust as needed
      height: 500px; // Adjust as needed
      overflow: hidden;
      border-radius: 12px;
      padding: 20px;
      margin-top: 10px;
    
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
        position: relative;
        z-index: 1; // Ensure the video is above the SVG
      }
    
      // SVG S-Shape
      .s-shape {
        position: absolute;
        top: 55%;
        left: 50%;
        width: 800px; // Adjust width to control the size of the "S" shape
        height: 800px; // Adjust height to control the size of the "S" shape
        transform: translate(-50%, -50%);
        z-index: 0; // Place it behind the video
      }
    }
    
    
  }
  
  // Responsive design for mobile
  @media (max-width: 768px) {
    .hero-section {
      flex-direction: column;
      text-align: center;
      padding: 30px;
  
      .hero-content {
        margin: 0;
        
        h1 {
          font-size: 2.5rem;
        }
  
        p {
          font-size: 1rem;
        }
  
        .cta-button {
          font-size: 1rem;
          padding: 8px 16px;
          width: 86%;
        }
      }
  
      .hero-image {
        display: flex;
        margin-top: -140px;
        padding: 0px;
        max-width: 350px;
        height: 350px;
        margin-right: 10px;

        video{
          border-radius: 10px;
          justify-content: center;
          width: 350px;
          height: 350px;
        }
      }
      .s-shape {
        padding: 0% !important;
        display: none;
      }
    }
  }
  


  @media (max-width: 768px) {
    h2 {
      font-size: 20px !important;
    }
    h3 {
      font-size: 16px !important;
    }

    p{
      font-size: 14px !important;
    }

  }
  

  .challenge-section {
    text-align: center;
    padding: 50px;
    color: #fff;
    background-color: #000;
  
    h2 {
      color: #BD89FF;
      margin-bottom: 20px;
      font-size: 2.5rem;
    }
  
    p {
      font-size: 1.5rem;
      color: #ccc;
      max-width: 75%;
      margin: 0 auto 40px;
    }
  
    .ChallengeVideos {
      display: flex;
      padding: 5px;
      gap: 70px;
      margin-left: 10px;
      justify-content: center;
  
      @media (max-width: 768px) {
        display: flex;
        gap: 10px;
        margin-left: 0;
        border-radius: 20px;
        padding: 0px;
      }
    }
  
    .Challenge-image {
      max-width: 100%;
      border-radius: 20px;
  
      video {
        width: 100%;
        height: 500px;
        border-radius: 20px;
        filter: drop-shadow(1px 1px 60px black);
  
        @media (max-width: 768px) {
          width: 100%;
          border-radius: 10px;
        }
  
        @media (max-width: 480px) {
          height: 200px;
        }
      }
    }
    
  }
  
  // Responsive adjustments
  @media (max-width: 768px) {
    .challenge-section {
      padding: 0px;
  
      h2 {
        font-size: 2rem;
      }
  
      p {
        font-size: 1.2rem;
        margin-bottom: 40px;
      }
    }
  }
  
  @media (max-width: 480px) {
    .challenge-section {
      padding: 0px;
  
      h2 {
        font-size: 1.8rem;
      }
  
      p {
        font-size: 1rem;
        margin-bottom: 30px;
      }
    }
  }
  
    
  


  //how to participate 
  .how-to-participate {
    padding: 27px;
    text-align: center;
    background-color: #000;
  
    h2 {
      color: #BD89FF;
      font-size: 2.5rem;
      margin-bottom: 30px;
    }
  
    .card-container {
      display: flex;
      gap: 30px;
      justify-content: center;
      flex-wrap: wrap;
    }
  
    .card {
      background-color: #1a1a1a;
      padding: 30px;
      border-radius: 20px;
      width: 300px;
      text-align: center;
      color: #fff;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      transition: background-color 0.3s ease, transform 0.3s ease;
      justify-content: center;
      align-items: center;
      
  
      &:hover {
        background-color: #7234fe;
        // Changes background color on hover
        transform: translateY(-5px);
        .icon img {
          filter: brightness(0) invert(1); // Adjust icon color for contrast if needed
        }
        p{
          color: #fff;
        }
      }
  
      .icon {
        margin-bottom: 10px;
  
        img {
          width: 50px; // Adjust as needed
          height: 50px;
        }
      }
  
      h3 {
        font-size: 1.5rem;
        margin: 10px 0;
      }
  
      p {
        font-size: 1rem;
        color: #aaa;
        margin-top: auto;
      }
    }
  
    @media (max-width: 768px) {
      .how-to-participate{
        padding-left: 0px !important;
      }
      .card-container {
        flex-direction: column;
        padding: 0px;
      }
  
      .card {
        width: 320px;
        margin-bottom: -10px;
      }
    }
  }
  


//FAQ
.questionsSection{
    
  .title{
       font-family: "Outfit", sans-serif;
      word-wrap: break-word;
      line-height: 60px;
      font-weight: 500;
      text-align: center;
      
      
  }
  .MuiAccordionSummary-content{
      margin: 0 0 9px 0!important;
      
     
  }
  :global(.MuiAccordion-root::before){
     display: none !important;
  }
  
}

.prizesSection {
  padding: 50px;
  background-color: #000;
  text-align: center;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 50px;

  .prizes-heading {
    font-size: 2.5rem;
    color: #BD89FF;
    margin-bottom: 30px;
    font-weight: bold;
  }

  .prizes-container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 30px;
    padding-top: 40px;

    .prizes-image {
      flex: 1;
      display: flex;
      justify-content: center;

      img {
        margin-top: 10px;
        width: 100%;
        max-width: 550px;
        height: 300px;
        border-radius: 20px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      }
    }

    .prizes-content {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .prize-description {
        text-align: center;
      }

      .prize-card {
        width: 250px;
        background-color: #1a1a1a;
        padding: 10px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        flex: 1;
        transition: transform 0.5s ease;
        position: relative;
        overflow: hidden;
  

        @media (min-width: 769px) {
          &:hover {
            transform: translateX(-150%) scale(0.5);
            ~ .prize-content {
              text-align: left;
              padding-left: 40px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .prize-icon-container {
          position: absolute;
          transform: translateX(-50%);
          top: 40px;
          transition: all 0.5s ease-out;

          .prize-icon {
            width: 40px;
            height: 40px;
            margin-left: 60%;
            transition: transform 0.5s ease-out;
          }
        }

        .prize-content {
          margin-top: 70px;
          transition: all 0.5s ease-out;
          width: 100%;

          h3 {
            font-size: 1.5rem;
            margin: 5px 0;
            font-weight: 600;
            transition: all 0.3s ease-out;
          }

          p {
            font-size: 1.3rem;
            font-weight: 500;
            color: #eee;
            margin: 5px 0;
            transition: all 0.3s ease-out;
          }

          .prize-description {
            display: block;
            font-size: 0.9rem;
            color: #ccc;
            transition: all 0.3s ease-out;
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: -50%;
          left: -50%;
          width: 200%;
          height: 200%;
          background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.1), transparent);
          transform: translateX(-100%);
          transition: transform 0s;
        }

        &:hover {
          transform: translateY(-5px);

          .prize-icon-container {
            text-align: left;
            padding-left: 70px;
          }

          &::before {
            transform: translateX(100%);
            transition: transform 1s ease-in-out;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 10px !important;

    .prizes-heading {
      font-size: 1.8rem;
      margin-bottom: 20px;
    }

    .prizes-container {
      flex-direction: column;
      gap: 20px !important;

      .prizes-image img {
        height: auto;
        max-width: 90%;
      }

      .prizes-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .prizes-content {
      .prize-card {
        width: 320px !important;
        padding: 15px;
        text-align: center;

        .prize-icon-container {
          transform: scale(0.8);
          margin-bottom: 15px;
        }

        .prize-content {
          h3 {
            font-size: 1.2rem;
          }

          p {
            font-size: 1rem;
          }

          .prize-description {
            font-size: 0.85rem;
          }
        }

        &:hover {
          transform: none;
        }
      }
    }
  }
}




//guidelines

.guidelines-Section {
  padding: 50px 0;
  background-color: #000 !important; 
  text-align: center;

  .guidelines-heading {
    font-size: 2.5rem;
    color: #BD89FF;
    margin-bottom: 30px;
    font-weight: bold;

    // Responsive font size for mobile
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .guide-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    flex-direction: column;
    background-color: #000 !important;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    // Left and Right Image Styling
    .guidelines-image-left, .guidelines-image-right {
      flex: 1;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      img {
        width: 100%;
        max-width: 615px;
        max-height: 630px;
        border-radius: 10px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      }

      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }

    // Center Cards Styling
    .guidelines-cards {
      display: flex;
      gap: 30px;
      justify-content: center;
      flex-wrap: wrap;
    }
      .guideline-card {
        background-color: #1a1a1a;
        padding: 30px;
        border-radius: 10px;
        width: 250px;
        text-align: center;
        color: #fff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        transition: background-color 0.3s ease, transform 0.3s ease;
        min-height: 300px;
        justify-content: center;
        align-items: center;

        &:hover {
          transform: translateY(-5px);
        }

        h3 {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 10px;
          color: #bd89ff; 
        }

        p {
          font-size: 1rem;
          color: #ccc;
          line-height: 1.5;
        }
      }

  }
  @media (max-width: 480px) {
    .guidelines-cards .guideline-card {
      padding: 10px; // Further reduce padding for very small screens
      h3 {
        font-size: 1.2rem;
      }
      p {
        font-size: 0.85rem;
      }
    }
  }
}



.testimonials-section {
  padding: 80px 20px;
  background-size: cover;
  background-position: center;
  text-align: center;
  color: #fff;
  position: relative;

  .testimonials-heading {
    font-size: 2.5rem;
    color: #BD89FF;
    margin-bottom: 20px;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 2rem;
      padding: 0 15px;
    }
  }

  .testimonial-content {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    padding: 30px;
    background: rgba(0, 0, 0, 0.6); // Dark overlay for readability
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);

    @media (max-width: 768px) {
      padding: 20px;
    }

    blockquote {
      font-size: 1.5rem;
      font-style: italic;
      margin: 0 0 20px;
      position: relative;
      color: #eee;

      @media (max-width: 768px) {
        font-size: 1.2rem;
      }

      &::before {
        content: "“";
        font-size: 2rem;
        color: #7234fe;
        position: absolute;
        top: -10px;
        left: -20px;

        @media (max-width: 768px) {
          font-size: 1.5rem;
          top: -5px;
          left: -15px;
        }
      }
    }

    .testimonial-info {
      display: flex;
      align-items: center;
      flex-direction: column;

      .user-avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-bottom: 10px;

        @media (max-width: 768px) {
          width: 50px;
          height: 50px;
        }
      }

      h3 {
        font-size: 1.3rem;
        color: #fff;
        margin: 5px 0;

        @media (max-width: 768px) {
          font-size: 1.1rem;
        }
      }

      .user-title {
        font-size: 0.9rem;
        color: #bd89ff;

        @media (max-width: 768px) {
          font-size: 0.8rem;
        }
      }
    }

    .navigation {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      transform: translateY(-50%);

      .arrow {
        background: none;
        border: none;
        color: #7234fe;
        font-size: 1.5rem;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #fff;
        }
      }

      .left-arrow {
        margin-left: -100px;

        @media (max-width: 768px) {
          margin-left: -5px;
          font-size: 1.2rem;
        }
      }

      .right-arrow {
        margin-right: -100px;

        @media (max-width: 768px) {
          margin-right: -5px;
          font-size: 1.2rem;
        }
      }
    }
  }

  // Additional adjustments for very small screens
  @media (max-width: 480px) {
    padding: 60px 10px;

    .testimonials-heading {
      font-size: 1.8rem;
    }

    .testimonial-content {
      padding: 15px;

      blockquote {
        font-size: 1rem;
      }

      .testimonial-info {
        .user-avatar {
          width: 40px;
          height: 40px;
        }

        h3 {
          font-size: 1rem;
        }

        .user-title {
          font-size: 0.75rem;
        }
      }

      .navigation .arrow {
        font-size: 1rem;
      }
    }
  }
}



//fade up animation 


@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;

  &.fade-up-active {
    opacity: 1;
    transform: translateY(0);
    animation: fadeUp 1s ease forwards;
  }
}

.ban{
  padding: 50px;
  @media (max-width: 768px) {
    padding: 10px;   
  }
}
.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #FA6D4F 0%, #7234F7 100%);
  color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  text-align: center;
  overflow: hidden; 
 

  .banner-content {
      position: relative;
      z-index: 1;
      max-width: 900px;

      .tagline {
          font-size: 1rem;
          background: rgba(255, 255, 255, 0.2);
          padding: 0.5rem 1rem;
          border-radius: 50px;
          display: inline-block;
          margin-bottom: 1rem;
      }

      h1 {
          font-size: 2.5rem;
          margin: 0.5rem 0;
      }

      p {
          font-size: 1.5rem;
          margin: 1rem 0 2rem;
      }

      .enroll-button {
          background-color: #fff;
          font-family: poppins;
          color: #000;
          padding: 0.75rem 2rem;
          border: none;
          border-radius: 5px;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
              background-color: #7234fe;
              color: #fff;
          }
      }
  }

  @media (max-width: 768px) {
      padding: 1.5rem;

      .banner-content {
        .tagline{
          font-size: 0.6rem;
        }
          h1 {
              font-size: 1.5rem;
          }

          p {
              font-size: 1rem;
          }

          .enroll-button {
              font-size: 1.3rem;
              padding: 0.8rem 1.5rem;
          }
      }
  }

  @media (max-width: 480px) {
      padding: 1rem;
      .banner-content {
          h1 {
              font-size: 1.25rem;
          }

          p {
              font-size: 0.875rem;
          }

          .enroll-button {
              font-size: 0.75rem;
              padding: 0.5rem 1rem;
          }
      }
  }
}
