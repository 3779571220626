// Add to your SCSS file

@keyframes fillGauge {
  from {
    stroke-dasharray: 0 251.2; // 251.2 is approximately π * 80 (circumference of the semicircle)
  }
  to {
    stroke-dasharray: 188.4 251.2; // 188.4 is 75% of 251.2
  }
}

@keyframes countUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.curve-container {
  position: relative;
  width: 160px;
  height: 100px;
  margin: 20px auto;
}

.gauge-background {
  fill: none;
  stroke: #E6E6E6;
  stroke-width: 14;
}

.gauge-fill {
  fill: none;
  stroke: url(#gaugeGradient);
  stroke-width: 14;
  stroke-linecap: round;
  transform-origin: center;
  animation: fillGauge 4s ease-out forwards;
}

.percentage-text {
  font-family: Poppins;
  font-weight: 700;
  fill: #7234f7;
  animation: countUp 0.3s ease-out forwards;
  animation-delay: 0.9s;
}

@media (max-width: 900px) {
  .curve-container {
    width: 120px;
    height: 75px;
  }
  
  .gauge-background,
  .gauge-fill {
    stroke-width: 10;
  }
}
