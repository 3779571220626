.courseDescription {
    background-color: #FFFFFF;
    border-radius: 16px;
    border: 0.5px solid #C8C8C8;
    overflow: hidden;
    margin-bottom: 20px;
    .header {

        display: flex;
        width: 100%;
        background: linear-gradient(to bottom, #100031 70px, transparent 25%);

        .course {
            color: #000;
            margin-bottom: 8px;
            font-family: "Outfit", sans-serif;
            font-weight: 500;
            line-height: 20px;
        }

        .institute {
            color: #6A6A6A;
            font-family: "Outfit", sans-serif;
            font-weight: 400;
            margin-bottom: 25px;
        }
    }

    .courseDescriptionContent {

        .text {
            color: #6A6A6A;

            font-family: "Outfit", sans-serif;
            font-size: 14px;
            padding-bottom: 12px;
            font-weight: 400;
            line-height: 1.5;
        }

        .title {
            color: #000000;
            font-family: "Outfit", sans-serif;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 16px;
            line-height: 1.25;
        }

        table {
           
            .key, .value {
                color: #000000;
                font-family: "Outfit", sans-serif;
                font-size: 14px;
                line-height: 1.25;
            }
            .key{
                font-weight: 400;
                padding: 26px 0 17px 0px !important;

            }
            .value{
                padding: 26px 0 17px 26px !important;
                font-weight: 500;
            }
        }
    }

    .border-none td {
        border: 1px solid #D0D0D0;
        ;
    }

    .border-none tr:first-child td {
        border-top: none;
    }

    .border-none tr:last-child td {
        border-bottom: none;
    }

    .border-none tr td:first-child {
        border-left: none;
    }

    .border-none tr td:last-child {
        border-right: none;
    }

    .knowmoreBtn,
    .shareBtn {
        font-size: 14px;
        font-family: "Outfit", sans-serif;
        box-shadow: none;
        border-radius: 6px;
        font-weight: 400;
        text-transform: none;
    }

    .shareBtn {
        color: #414141;
        border: 1px solid #A4A4A4;
    }

    .knowmoreBtn {
        border: 0.5px solid #7234F7;
        background-color: #7234F7;
        color: white;
    }
    .knowmoreBtn:hover {
        background-color: #7234F7; /* Maintain the same background color */
        color: white; /* Maintain the same text color */
        border: 0.5px solid #7234F7; /* Maintain the same border */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add box shadow */
    }


    @media (max-width: 900px) {
        .header {
            width: 100%;
            background: linear-gradient(to bottom, #100031 45px, transparent 10%);
        }

        .courseDescriptionContent {
            .title {
                padding-bottom: 16px;
            }
        }
    }
}


.courseDescNavContent {
    font-family: "Outfit", sans-serif !important; 
    font-weight: 400 !important;
    line-height: 1 !important;


    & .tag {
      color: #FE7465;
      cursor: pointer;
    }
  }

 