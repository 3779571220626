.whyCoursevita{
  padding-top: 10px;
  padding-bottom: 20px;
    .header{
        text-align: center;
        .headText{
            font-weight: 500px;
            font-family: "Outfit", sans-serif;
            word-wrap: break-word;
        }
        .subText{
            color: #000000;
            font-family: "Outfit", sans-serif;
            word-wrap: break-word;
        }
      }
    .frameWrapper {
      display: flex;
      justify-content: space-around;
      
      .column1,.column3{
        flex-basis: 33%;
      }
      .column2{
        flex-basis: 30%;
        min-height: 600px;
        background-color: #0F002E;
        margin: 12px;
        text-align: center;
        position: relative;
        overflow: hidden;
        .label{
            color: #BD89FF;
            font-weight: 700;
            font-family: "Outfit", sans-serif;
            word-wrap: break-word;
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 6px;
        }
        .subText{
            color: #FFFFFF;
            font-weight: 300;
            font-family: "Outfit", sans-serif;
            word-wrap: break-word;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 35px;
        }
        .exploreBtn{
            color: #000000;
            background-color: #FFFFFF;
            text-transform: none;
            padding: 8px 18px;
            font-weight: 500;
            font-family: "Outfit", sans-serif;
            word-wrap: break-word;
            line-height: 20px;
        }
      }
    }
    
}