.top-wrapper {
  background: var(
    --vc,
    linear-gradient(
      180deg,
      rgba(201, 194, 255, 0) -42.79%,
      rgba(165, 108, 255, 0.08) 100%
    )
  );
}

.header-text {
  text-align: center;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  color: #fe7465;
}

.normal-text {
  color: var(--Filled, #000);
  text-align: center;
  font-family: Poppins !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

@media (max-width: 900px) {
  .card {
    flex-direction: column-reverse !important;
  }
  .header-text {
    font-family: Outfit !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: normal !important;
  }
  .normal-text {
    font-size: 16px !important;
  }
  .watermark-mid {
    width: 120px !important;
    height: auto !important;
  }
  .w2 {
    top: auto !important;
    bottom: 0 !important;
  }
  .sy {
    width: 61px !important;
    height: auto !important;
  }
  .s1 {
    bottom: -20px !important;
    right: -20px !important;
  }
  .s2 {
    left: auto !important;
    top: -25px !important;
    right: -27px !important;
  }
  .img-mid {
    max-width: 350px !important;
    height: auto !important;
  }
}
