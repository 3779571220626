.header-middle-project {
  color: var(--Filled, #fe7465);
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-align: center;
}
.normal-middle-project {
  color: var(--Filled, #000);
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.top-wrapper-middle-project {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 40px 10px;
  gap: 10px;
  width: 100% !important;
}

.card-wrapper-middle-project {
  display: flex;
  flex-direction: column;
  padding: 15px !important;
  gap: 20px !important;
  border-radius: 12px !important;
  background: #fff !important;
  justify-content: space-between !important;
}

.textHR-middle-project {
  color: var(--Filled, #000) !important;
  font-family: Outfit !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.header-text-middle-project {
  color: var(--Filled, #000) !important;
  font-family: Outfit !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important;
}

.normal-text-middle-project {
  color: var(--Filled, #000) !important;
  text-align: justify !important;
  font-family: Outfit !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 150% !important; /* 21px */
}

.prerequisitesH-middle-project {
  color: #8a8a8a !important;
  font-family: Outfit !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important; /* 21px */
}

.prerequisites-middle-project {
  color: var(--Filled, #000) !important;
  font-family: Outfit !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important; /* 21px */
}

.btn-middle-project {
  border-radius: var(--radius-radius-md, 8px) !important;
  border: 1px solid #bd89ff !important;
  background: #fff !important;
  color: #7234f7 !important;
  font-family: Outfit !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important; /* 24px */
  text-transform: none !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

@media (max-width: 900px) {
  .top-wrapper-middle-project {
    grid-template-columns: 1fr;
  }
  .header-middle-project {
    font-size: 20px !important;
  }
  .normal-middle-project {
    font-size: 16px !important;
  }
}

.filter-box {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 40px 10px;
  gap: 10px;
  width: 100% !important;
}

.search {
  display: flex;
  padding: 15px !important;
  padding-left: 20px;
  gap: 15px !important;
  border-radius: 12px !important;
  background: #fff !important;
}

.project-search {
  flex-grow: 1;
  padding: 8px;
  border: none;
  font-size: medium;
  outline: none;
}

.filter {
  display: flex;
  align-items: center;
}

.select-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 15px 20px !important;
  border-radius: 12px;
}

select {
  width: 100%;
  padding: 5px;
  border: none;
  font-size: medium;
  background: transparent;
  outline: none;
  appearance: none;
}

.select-icon {
  position: absolute;
  right: 20px;
  pointer-events: none;
  width: 20px;
  height: 20px;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 0px 24px;
  padding: 16px 0px;
  justify-items: center;
}
@media only screen and (max-width: 600px) {
  .project-grid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.project-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 32px 0px;
  }

  .pagination-controls {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
  }

  .MuiPagination-ul li button {
    color: #000; 
    border-radius: 8px; 
  }
  
  .MuiPagination-ul li button.Mui-selected {
    background-color: #3f3d56; 
    color: #fff; 
  }
  
  .pagination-box{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .line{
    width: 100%;
    border: 1px solid #D3D3D3;
    border-bottom-width: 1px;
  }


.tbl {
  display: flex;
}

.stbl {
  display: none;
}

@media only screen and (max-width: 600px) {
  .tbl {
    display: none;
  }

  .stbl {
    display: flex;
  }
}
