.workshops {
  background: #f3f4fd;
  padding: 20px 1rem;
  text-align: center;

  .headerText {
    .text1,
    .text2 {
      font-family: "Poppins", sans-serif;
      line-height: 1;
    }

    .text1 {
      margin-top: 20px;
      font-weight: 700;
      color: #ff7262;
      margin-bottom: 16px;
      @media (max-width: 600px) {
        margin-bottom: 10px;}
    }

    .text2 {
      color: #0f002e;
      font-weight: 400;
    }
  }

  .btnWrapper {
    display: flex;
    justify-content: center;

    .workshopBtn {
      border-radius: 12px;
      background: #0f002e;
      font-weight: 500;
      line-height: 1;
      text-transform: none;
      font-family: "Outfit", sans-serif !important;

      @media (max-width: 600px) {
        border-radius: 8px;}
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 30px !important;
    opacity: 1;
    color:#7233f7;
  }
  .slick-track {
    display: flex !important;
    justify-content: space-around;
  }

  .slick-slide {
    height: inherit !important;
    width: 400px !important;
  }

  .slick-slide > * {
    height: 100% !important;
  }

  .card-landing-workshop {
    border-radius: 12px;
    background-color: #ffffff;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .workshopName,
    .date,
    .mentor,
    .registerBtn {
      font-family: "Outfit", sans-serif !important;
    }

    .workshopName {
      color: #0f002e;
      font-weight: 500;
      text-align: left;
      line-height: 1.2;
    }

    .mentor {
      color: #0f002e;
      text-align: left;
      font-weight: 300;
      line-height: 1.2;
      padding-bottom: 7px;
    }

    .dateContainer {
      display: flex;
      padding-bottom: 13px;
    }

    .date {
      color: #737373;
      font-weight: 400;
      align-self: center;
      line-height: 1.2;
    }

    .registerBtn {
      border-radius: 8px;
      border: 1px solid #7233f7;
      text-transform: none;
      width: 100%;
      padding: 13px 0 15px 0;
      color: #7233f7;
      font-size: 16px;
      font-weight: 400;
    }
  }

  // }

  .viewmoreBtn {
    border-radius: 12px;
    border: 1px solid #0f002e;
    text-transform: none;
    font-size: 20px;
    font-family: "Outfit", sans-serif !important;
    font-weight: 500;
    color: #0f002e;
    width: 326px;
    line-height: 1;
    padding: 20px;
  }
}

@media (min-width: 2000px) {
  .cardsContainer {
    width: 66%;
    margin: auto;
  }
}
