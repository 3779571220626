.video-testimonials {
    .testimonials-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      margin: 0 auto;
      
      .title {
        font-weight: 500;
        color: var(--text-primary);
        margin-bottom: 2rem;
        
        @media (max-width: 600px) { // xs
          font-size: 20px;
          margin-bottom: 1.5rem;
        }
        
        @media (min-width: 600px) and (max-width: 960px) { // sm
          font-size: 22px;
          margin-bottom: 2rem;
        }
        
        @media (min-width: 960px) { // md and up
          font-size: 24px;
          margin-bottom: 2.5rem;
        }
      }
    }
    
    .video-grid {
      .video-container {
        width: 100%;
        margin: 0 auto;
        border-radius: 12px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        
        &:hover {
          .play-button {
            background-color: rgba(0, 0, 0, 0.7);
          }
        }
        
        .video-element {
          object-fit: cover;
          border-radius: 12px;
          width: 100%;
          height: 100%;
        }
        
        .play-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color 0.3s ease;
          z-index: 2;
          
          @media (max-width: 600px) { // xs
            width: 50px;
            height: 50px;
            
            .play-icon {
              color: white;
              font-size: 30px;
            }
          }
          
          @media (min-width: 600px) and (max-width: 960px) { // sm
            width: 60px;
            height: 60px;
            
            .play-icon {
              color: white;
              font-size: 35px;
            }
          }
          
          @media (min-width: 960px) { // md and up
            width: 70px;
            height: 70px;
            
            .play-icon {
              color: white;
              font-size: 40px;
            }
          }
        }
      }
    }
  }
  
  // Add this to your existing SCSS file
  .desktop-break {
    display: none;
    
    @media (min-width: 768px) {
      display: inline;
    }
  }