.header-text-join {
  color: var(--Filled, #fe7465) !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}
.header-text-join-2 {
  color: var(--Filled, #000) !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.normal-text-join {
  color: var(--Filled, #000) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.card-wrapper-join {
  display: flex !important;
  gap: 15px !important;
  padding: 15px 10px !important;
  justify-content: space-evenly !important;
  align-items: stretch !important;
  flex-wrap: wrap;
}

.card-subwrapper-join {
  padding: 10px !important;
  gap: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 380px !important;
  border-radius: 12px !important;
  border: 0.5px solid #b8b8b8 !important;
  background: #fff !important;
}

.isLive-join {
  border-radius: 4px !important;
  border: 0.5px solid #6b6b6b !important;
  background: rgba(244, 244, 244, 0.1) !important;
  padding: 3px 23px 3px 20px !important;
  display: flex;
  color: var(--Filled, #000) !important;
  font-family: Outfit !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.title-join {
  color: #525252 !important;
  font-family: Outfit !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.date-time-join {
  color: var(--Filled, #000) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.calClock-join {
  width: 20px !important;
  height: 20px !important;
}

.btn-join {
  color: #7234f7 !important;
  font-family: Outfit !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border-radius: 4px !important;
  border: 1px solid #7234f7 !important;
  flex: 1 !important;
}

.gradient-bg-2 {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  padding: 50px 150px !important;
  position: relative;
  // width: 100vw;
  overflow: hidden;
  gap: 20px !important;
  background: var(
    --vc,
    linear-gradient(
      180deg,
      rgba(201, 194, 255, 0) -42.79%,
      rgba(165, 108, 255, 0.08) 100%
    )
  );
}

.header-text-join-3 {
  text-align: center;
  color: var(--Filled, #fe7465) !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.normal-text-join-2 {
  color: var(--Filled, #000) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  max-width: 516px !important;
}

.btnAndTimeWrapper {
  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
}

.wrapper-join-first {
  flex: 1 !important;
}

.wrapper-join-second {
  flex: 1 !important;
  display: flex;
  gap: 20px;
  justify-content: end !important;
}

.image-wrapper-join {
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
}

.image-wrapper-join-2 {
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
}

.circleW {
  position: absolute !important;
}

.cw1 {
  left: 0px;
  top: 0px;
}

.cw2 {
  left: 9px;
  top: 57px;
}

.cw3 {
  bottom: 0px;
  left: 68px;
}

.image-slider-join {
  overflow: hidden;
  padding: 20px;
  display: none !important;
  white-space: nowrap;
  width: 100%;
  z-index: 2 !important;
}

.image-innerSlider-join {
  display: inline-block;
  width: 100%;
  z-index: 2 !important;
}

.images-slider-join {
  width: 50%;
  height: auto;
  z-index: 2 !important;
  padding: 8px;
}

@media (max-width: 900px) {
  .header-text-join,
  .header-text-join-2,
  .header-text-join-3 {
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }
  .normal-text-join,
  .normal-text-join-2 {
    font-size: 16px !important;
  }
  .gradient-bg-2 {
    flex-direction: column !important;
    padding: 50px 10px !important;
  }
  .image-wrapper-join-2 {
    display: none !important;
  }
  .isLive-join {
    font-size: 12px !important;
  }
  .btnAndTimeWrapper {
    flex-direction: column !important;
  }
  .btn-join {
    margin-top: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .image-wrapper-join {
    display: none !important;
  }
  .card-subwrapper-join {
    min-width: 340px !important;
  }
  .image-slider-join {
    display: flex !important;
  }
}
