.inputWrapper{
    background-color: #FFFFFF;
    width: 100%;
    border-radius: 6px;
    height:49px !important;
    display: flex;
    align-items: center;

input{
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: 14px;
    width: 100%;
    margin-left: 5px;
}
input:focus{
    outline: none;
}

}
@media (max-width:900px){
    .inputWrapper{border: 0.5px solid #7234F7;

    }
}