.demoWrapper {
  text-align: center;
  background-color: rgba(119, 43, 216, 0.04);
  padding: 20px;
  .headerWrapper {
    position: relative;
    .arrowLine{
      position: absolute;
      top: -10%;
    }
    .headText {
      color: #000000;
      font-family: "Outfit", sans-serif;
      word-wrap: break-word;
      line-height: 1.5;
      font-weight: 700;
      padding-bottom: 10px;
    }
    .subText {
      font-size: 20px;
      line-height: 26px;
      color: #000000;
      font-weight: 400;
      font-family: "Outfit", sans-serif;
      word-wrap: break-word;
    }
  }
  .contentWrapper {
    display: flex;
    position: relative;
    justify-content: center;
   
    .gradientImg {
      position: absolute;
      z-index: 1;
    }
    .bookHeader {
      text-align: center;
      color: #100031;
      font-family: "Outfit", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: normal;
    }
  
    .contentform {
      background-color: white;
      padding: 30px 45px;
      position: relative;
      z-index: 2;
      text-align: start;
      .formDetails {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
      }
      .formInputHeader {
        color: #939393;
        font-family: "Outfit", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 6px;
      }
      .formInput {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 20px;
        &.halfInput {
          .formText {
            width: 100%;
          }
        }
        .formText {
          .MuiFormLabel-root {
            font-size: 14px;
            margin-top: -5px;
          }
          .MuiInputBase-input {
            height: 20px;
            padding: 8px 10px;
            border-radius: 6px;
            border: 1px solid #939393;
            font-size: 14px;
            line-height: normal;
          }
          &.selectForm .MuiInputBase-input{
            padding-top: 10px;
          }
        }
      }
      .contactButton {
        margin-top: 20px;
        height: 40px;
        background-color: #7233f7 !important;
        width: 100%;
        text-transform: none;
        border-radius: 6px;
        padding: 10px;
        font-size: 14px;
        font-family: "Outfit", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .freeTagline{
        font-family: "Outfit", sans-serif;
        font-weight: 500;
        font-size: 14px;
        padding-top: 20px;

      }

    }
    .MuiButtonBase-root{
      box-shadow: none;
    }
  }
  @media (min-width: 1400px) {
    .bookingImg {
      width: 75%;
    }
    .gradientImg{
      top: -20%;
    }
  }
  @media (max-width: 1200px) {
   .contentform{
    margin-top: 32px;
    margin-bottom: 16px;
   }
   .bookContent{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contentform{
    padding: 30px 24px !important;
  }
  .freeTagline{
    
  }
}
    
  @media (max-width: 800px) {
    .gradientImg, .arrowLine{
     display: none;

   } .contentform{
    padding: 30px 16px !important;
  }.demoWrapper{
    padding: 0px;
  }.contentform{
    margin-top: 45px;

    }
  }
  @media (max-width: 1100px) {
    .arrowLine{
     left: -2%;
     top: -20% !important;
   }
  }
}

/* Default styling for the ReCAPTCHA container */
/* Default styling for the ReCAPTCHA container */
.recaptcha-container {
  transform: scale(1); /* Default size */
  transform-origin: center;
  margin: 0 auto; /* Centered alignment by default */
}

/* For tablets and smaller devices */
@media (max-width: 768px) {
  .recaptcha-container {
    transform: scale(0.85); /* Scale down */
    margin: 0 auto 0 0; /* Reset left margin */
    margin-right: 170px; /* Move slightly to the right */
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .recaptcha-container {
    transform: scale(0.75); /* Further scale down */
    margin: 0 auto 0 0; /* Reset left margin */
    margin-right: 130px; /* Slightly more to the right */
  }
}


