.filtersDrawer {

  .filtersDrawerheader {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    border-bottom: 1px solid #F1F1F1;

    .filters,
    .clearAll {

      font-family: "Outfit", sans-serif !important;
      font-weight: 500;
      line-height: 20px;
    }

    .filters {
      color: #0F002E;
      font-size: 16px;

    }

    .clearAll {
      color: #7234F7;
      font-size: 12px;


    }
  }

  .drawerContent {
    display: flex;
    height: calc(100% - 80px);

  }

  .MuiPaper-root {
    height: 80% !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .buttonsWrapper {
    border-top: 1px solid #F1F1F1;
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    gap: 16px;
    background-color: white;
    z-index: 10;
    padding: 16px 16px 24px 16px;

    .cancelBtn {
      border-radius: 26px;
      border: 0.5px solid #000000;
      color: #000000;
    }

    .applyFiltersBtn {
      border-radius: 26px;
      background-color: #7234F7;
    }
  }

  .buttonsWrapper>*,
  .categoriesContainer .drawerItem .label,
  .optionsContainer .option .label {
    font-size: 14px !important;
    font-family: "Outfit", sans-serif !important;
    font-weight: 400 !important;
  }

  .categoriesContainer {
    width: 40%;
    overflow-y: auto;
    height: 100%;
    border-right: 1px solid #F1F1F1;

    .drawerItem {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 15px 0px 15px 16px;
      border-bottom: 1px solid #F1F1F1;

    }

  }

  .optionsContainer {
    overflow-y: auto;
    height: 100%;

    .option {
      display: flex;
      align-items: center;
      gap: 6px;
      padding-bottom: 12px;

      .label {
        color: #595361;
      }
    }
  }

  .MuiCheckbox-root {
    padding: 0px !important;
    color: #7234F7 !important;
  }

  .Mui-checked {
    color: #7234F7 !important;
  }

  .buttonsWrapper>* {
    border-radius: 26px !important;
    padding: 10px 20px !important;
    font-size: 14px !important;
    width: 138px !important;
    height: 42px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    font-family: "Outfit", sans-serif !important;
  }
}