.coursePreviewWrapper {
  overflow: hidden;
  .coursePreview {
    background-color: #FFFFFF;
    display: flex;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    .courseDetails {
      .tag1 {
        font-family: "Outfit", sans-serif;
        word-wrap: break-word;
        font-size: 20px;
        font-weight: 400;
        color: #BD89FF;
      }
      .courseName {
        font-size: 36px;
        font-family: "Outfit", sans-serif;
        word-wrap: break-word;
        font-weight: 600;
        line-height: 1.3;
      }
      .courseDuration{
        
        color: #772bd8;
        display: flex;
        width: max-content;
        gap: 12px;
        padding: 7px 15px 7px 12px;
        background-color: #772BD80F;
       border-radius: 6px;
        align-items: center;
        margin: 13px 0px 26px 0px;
        .label{
            font-family: "Poppins", sans-serif;
            word-wrap: break-word;
            font-size: 14px;
            font-weight: 500;
        }
      }
      .courseDesc{
        font-size: 16px;
        color: #000000;
        font-family: "Poppins", sans-serif;
        word-wrap: break-word;
        font-weight: 300;
      }
      .btnWrapper{
        .btn1,.btn2{
           text-transform: none;
           min-width: 190px;
           border-radius: 6px;
           padding: 8px 18px;  
           font-family: "Poppins", sans-serif;
           word-wrap: break-word;
           font-size: 16px;
           margin-top: 40px;

        }
        .btn1{
        color: #FFFFFF;  
        font-weight: 600;
        background-color: #7234F7;
        border: 1px #FFFFFF solid;
        margin-right: 24px;
        font-size: 16px;
        }
        .btn2{
            color: #7234F7;
            border: 1px #7234F7 solid;
            font-size: 16px;
            background-color: #FFFFFF;
        }

      }
      
    }
    .rightImg{
        
        border-radius: 25px;
        margin-left: 8px;
    }
    
    @media (max-width: 800px) {
      .btn1,.btn2{
       width: 100%;
       margin-top: 10px !important;
      }
      .tag1{
        
        font-size: 14px !important;
      }
      .courseName{
        font-size: 24px !important;
      }.courseDesc{
        font-size: 14px !important;}
        .courseDuration{
          
          margin: 7px 0px 16px 0px !important;
          .label{
            font-size: 12px !important;
          }
        }
  }
}}
