.blogWrapper {
  display: flex;
  flex-direction: column;
  gap: 26px;
  font-family: "Outfit", sans-serif !important;
  .title,
      .date,
      .readMoreBtn,
      .categoryBtns ,.blog{
        font-family: "Outfit", sans-serif !important;
        word-wrap: break-word;
      }
  .cardWrapper {
    display: flex;
    border-radius: 16px;
    border: 0.5px #a9a9a9 solid;
    justify-content: space-between;

    .contentWrapper {
      position: relative;

      .title,
      .date,
      .readMoreBtn,
      .categoryBtns .blog {
        font-family: "Outfit", sans-serif !important;
        word-wrap: break-word;
      }

      .title {
        padding-bottom: 6px;
        font-weight: 700;
      }

      .date {
        padding-bottom: 6px;
        font-size: 14px;
        color: #393939;
        font-weight: 400;
      }

      .blog {
        font-size: 16px;
        color: #838383;
        font-weight: 300;

        padding-top: 16px;
      }

      .readMoreBtn {
        color: #100031;
        font-weight: 500;
        padding: 0px;
        font-size: 16px;
        text-transform: none;
      }
    }
  }

  // category button css

  .categoryBtns {
    text-transform: none;
    border: 0.5px #100031 solid;
    color: #727272;
    font-size: 16px;
    font-weight: 500;

    &.contained {
      color: #FFFFFF;
      background-color: #1C1917
    }
  }

}

.header {
  font-family: "Outfit", sans-serif !important;
  word-wrap: break-word;
  font-weight: 700 !important;
  text-align: center;
}

.subheader {
  color: #1C1917;
  text-align: center;
  font-family: "Outfit", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}
.searchBar{
  box-shadow: rgba(0, 0, 0, 0.25);
}
.categoryContainer {
  margin: 0px 0px 0px 0px;
  width: 100%;
  max-width: 100%;
  box-shadow: 0px rgba(129, 130, 129, 0.5) 0px 0px;
  //filter: blur(-8px); // Apply blur effect
 

  
  .categoryBtns {
    text-transform: none;
    border: 0.5px solid #100031;
    color: #727272;
    font-size: 16px;
    font-weight: 500;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    box-shadow: -10px 0px 10px rgba(225, 225, 225, 0.3) inset;

    &.contained {
        color: #FFFFFF;
        background-color: #1C1917;
    }
}

}



.blogContentWrapper {
  text-align: justify;
  font-family: "Outfit", sans-serif;
  .MuiTypography-root{
  font-family: "Outfit", sans-serif;

  }

  .blogContent {
    border-radius: 16px;
    border: 0.5px solid #C8C8C8;
    background: #FFF;
    
  }

  .blogHeader {
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;

    & .blogHeaderContent {
      color: #FE7465;
      cursor: pointer;
    }
  }

  .blogHeaderTech {
    font-size: 14px;
    border-radius: 4px;
    background: rgba(228, 228, 228, 0.27);
    padding: 6px 22px;
    max-width: fit-content;
    margin-bottom: 24px;
  }

  .blogContentTitle {
    color: #3C3C3C;
    font-family: "Outfit", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 6px;
  }

  .blogContentDate {
    color: #393939;
    font-family: "Outfit", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}
}

.trendingBlogWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  justify-content: space-between;
  color: #FFF;
  font-family: "Outfit", sans-serif;

  .trendingWrapper {
    max-width: 45%;
    border-radius: var(--radius-radius-md, 8px);
    border: 1.4px solid rgba(255, 255, 255, 0.40);
    background: rgba(128, 128, 128, 0.01);
    background-blend-mode: luminosity;
    backdrop-filter: blur(62.599998474121094px);
  }

  .trendingTitle {
    max-width: fit-content;
    
    background-color: #FF7262;
  }

  .trendingBlog {
    text-transform: none;
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    border: none;
    background: rgba(169, 169, 169, 0.5);
    border-radius: 8px; 
    line-height: normal;
    cursor: pointer;
  }
}
.coursesSection {
  font-family: "Outfit", sans-serif;
  text-align: center;
  .headersSection {
    .headText {
      color: #0f002e;
      font-weight: 700;
      word-wrap: break-word;
    }
    .subText {
    
      color: #ff7262;
      font-weight: 700;
      word-wrap: break-word;
    }
  }
}
  .cardsWrapper {
    .courseCards {
      text-align: left;
      gap: 2%;
      flex-wrap: nowrap;
      display: flex;
      justify-content: center;
    }
  }

.dangerhtml{
    font-family: 'Outfit', sans-serif;
    text-align: left;
.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
}

.main-title {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
}

.sub-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
    color: #FE7465 !important;
}

.content {
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 24px;
    margin: 0;
    color:#1C1917 !important;
}

.content-underline {
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
    line-height: 24px;
    margin: 10px 0;
}

.list {
    margin: 0;
    padding-inline-start: 48px;
    color: #FE7465;
}

.list-item {
    list-style-type: decimal;
    font-size: 16px;
    font-weight: 700;
    background-color: transparent;
    font-style: normal;
    font-variant: normal;
    text-decoration: none;
    vertical-align: baseline;
    margin: 20px 0 10px 0;
    color: #FE7465;
}

.bullet-list {
    margin: 0;
    padding-inline-start: 48px;
    color: #1C1917;
}

.bullet-list-item {
    list-style-type: disc;
    font-size: 16px;
    font-weight: 700;
    background-color: transparent;
    font-style: normal;
    font-variant: normal;
    text-decoration: none;
    vertical-align: baseline;
    margin: 20px 0 10px 0;
}

.link {
    color: #4a86e8;
    text-decoration: none;
}

}


@media (max-width: 768px) {  /* Mobile screen size breakpoint */
  .trendingBlogWrapper {
    .trendingWrapper {
      height: 400px;  /* Increase height to accommodate the content */
    }
  }
}
