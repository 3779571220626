.prev-main{
  background-color: #F3F4FD;
}
/* Add these styles to your eventsPrev.scss file */

/* Registration/Waitlist Popup Styles */
.registration-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.registration-popup {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  padding: 24px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  animation: popup-appear 0.3s ease-out;
}

@keyframes popup-appear {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
}

.popup-header h3 {
  margin: 0;
  font-size: 20px;
  color: #8940FF;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #999;
  transition: color 0.2s;
}

.close-button:hover {
  color: #333;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border 0.2s;
}

.form-group input:focus {
  outline: none;
  border-color: #8940FF;
}

.disabled-input {
  background-color: #f5f5f5;
  color: #777;
  cursor: not-allowed;
}

.error-message {
  color: #e74c3c;
  margin-bottom: 16px;
  font-size: 14px;
  padding: 8px 12px;
  background-color: #fdedec;
  border-radius: 4px;
}

.success-message {
  text-align: center;
  padding: 20px 0;
}

.success-message h4 {
  color: #27ae60;
  margin-bottom: 12px;
  font-size: 18px;
}

.success-message p {
  margin-bottom: 20px;
  color: #555;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.primary-button, .secondary-button {
  padding: 12px 20px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.primary-button {
  background-color: #8940FF;
  color: white;
  border: none;
}

.primary-button:hover {
  background-color: #7830e0;
}

.primary-button:disabled {
  background-color: #c4a3ff;
  cursor: not-allowed;
}

.secondary-button {
  background-color: white;
  color: #8940FF;
  border: 1px solid #8940FF;
}

.secondary-button:hover {
  background-color: #f9f5ff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .registration-popup {
    width: 95%;
    padding: 16px;
  }
  
  .form-actions {
    flex-direction: column;
  }
  
  .primary-button, .secondary-button {
    width: 100%;
  }
}
.event-detail-page {
    font-family: 'Inter', sans-serif;
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    
    .breadcrumb {
      margin-bottom: 30px;
      font-size: 14px;
      color: #666;
      
      a {
        color: #6c5ce7;
        text-decoration: none;
        
        &:hover {
          text-decoration: underline;
        }
      }
      
      span {
        color: #888;
      }
    }
    
    .event-container {
      display: grid;
      grid-template-columns: 280px 1fr 300px;
      gap: 30px;
      
      @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
        
        .event-sidebar {
          grid-column: 1;
          grid-row: 1;
        }
        
        .event-main-content {
          grid-column: 2;
          grid-row: 1;
        }
        
        .upcoming-events-sidebar {
          grid-column: 1 / -1;
          grid-row: 2;
          width: 100%;
        }
      }
      
      @media (max-width: 900px) {
        grid-template-columns: 1fr;
        
        .event-sidebar, .event-main-content {
          grid-column: 1;
        }
        
        .event-sidebar {
          grid-row: 1;
        }
        
        .event-main-content {
          grid-row: 2;
        }
        
        .upcoming-events-sidebar {
          grid-row: 3;
        }
      }
    }
    
    .event-sidebar {
      .sidebar-header {
        background-color: #1a237e;
        color: white;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 10px;
        
        .hosted-by {
          padding: 15px;
          background-color: #0d1442;
          display: flex;
          flex-direction: column;
          
          span {
            font-size: 12px;
            margin-bottom: 10px;
          }
          
          .coursevita-logo {
            img {
              max-width: 150px;
            }
          }
        }
        
        .event-title-card {
          padding: 20px;
          
          h2 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 15px;
          }
          
          .event-date-time {
            margin-bottom: 15px;
            font-size: 14px;
            
            span {
              display: block;
              margin-bottom: 5px;
            }
          }
          
          .event-location {
            font-size: 14px;
            
            p {
              margin-bottom: 5px;
            }
            
            .location-address {
              opacity: 0.8;
              font-size: 12px;
            }
          }
        }
        
        .mentors-section {
          padding: 0 20px 20px;
          
          h3 {
            font-size: 16px;
            margin-bottom: 15px;
          }
          
          .mentors-avatars {
            display: flex;
            
            .mentor-avatar {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              overflow: hidden;
              margin-right: -10px; // Negative margin for overlap
              border: 2px solid #1a237e; // Add border to separate overlapping avatars
              background: white; // Background color to prevent transparency issues
              
              &:last-child {
                margin-right: 0; // Remove margin from last avatar
              }
              
              &:hover {
                transform: translateY(-2px);
                z-index: 1; // Bring hovered avatar to front
              }
              
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
      
      .hosted-by-people {
        
        h3 {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 20px;
          color: #8940FF;
          padding-bottom: 13px;
          border-bottom: 1px solid #000;
        }
        
        .host-info {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          
          .host-avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 15px;
            
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          
          .host-name {
            flex: 1;
            font-weight: 500;
          }
          
          .host-social {
            display: flex;
            
            .social-icon {
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              margin-left: 5px;
              font-size: 10px;
              font-weight: bold;
              text-decoration: none;
            }
          }
        }
        
        .attendees-info {
          border-top: 1px solid #eee;
          padding-top: 20px;
          margin-top: 20px;
          
          .attendees-count {
            display: block;
            margin-bottom: 15px;
            font-weight: 500;
          }
          
          .attendees-avatars {
            display: flex;
            align-items: center;
            
            .avatar-stack {
              display: flex;
              margin-right: 10px;
              
              .avatar {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: #ddd;
                border: 2px solid white;
                margin-left: -10px;
                
                &:first-child {
                  margin-left: 0;
                }
              }
            }
            
            .others-count {
              font-size: 12px;
              color: #666;
            }
          }
        }
      }
    }
    
    .event-main-content {
      .event-header {
        margin-bottom: 30px;
        
        h1 {
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 20px;
          color: #333;
          
          @media (max-width: 600px) {
            font-size: 24px;
          }
        }
        
        .event-meta {
          display: flex;
          flex-direction: column;
          gap: 30px;
          
          .date-time, .location {
            display: flex;
            
            .icon {
              border-radius: 10px;
              border: 1px solid rgba(0, 0, 95, 0.08);
              margin-right: 15px;
              padding: 5px;
              height: 45px;
              width: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            
            .info {
              display: flex;
              flex-direction: column;
              gap: 5px;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
            }
          }
        }
      }
      
      .registration-section {
        background-color: white;
        border-radius: 12px;
        padding: 20px;
        margin-bottom: 30px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
        
        h3 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 15px;
          color: #333;
        }
        
        p {
          margin-bottom: 20px;
          color: #666;
        }
        
        .register-button {
          display: block;
          width: 100%;
          padding: 14px;
          border-radius: 6px;
          border: none;
          font-weight: 600;
          cursor: pointer;
          font-size: 16px;
          text-align: center;
          
          &.register-now {
            background-color: #7234F7;
            color: white;
            
          }
          
          &.join-waitlist {
            background-color: #7234F7;
            color: white;
            
          }
        }
      }
      
      .about-event {
        margin-bottom: 30px;
        
        h3 {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 20px;
          color: #8940FF;
          padding-bottom: 13px;
          border-bottom: 1px solid rgba(0, 0, 95, 0.08);
        }
        
        p {
          line-height: 1.6;
          color: #444;
        }
      }
      
      .youtube-video {
        margin-bottom: 30px;
        
        h3 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 15px;
          color: #333;
        }
        
        .video-container {
          position: relative;
          border-radius: 12px;
          overflow: hidden;
          padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
          height: 0;
          
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      
      .location-section {
        h3 {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 20px;
          color: #8940FF;
          padding-bottom: 13px;
          border-bottom: 1px solid rgba(0, 0, 95, 0.08);
        }
        
        .location-name {
          font-weight: 500;
          margin-bottom: 5px;
        }
        
        .location-address {
          color: #666;
          margin-bottom: 15px;
        }
        
        .map-container {
          position: relative;
          border-radius: 12px;
          overflow: hidden;
          
          .map-responsive {
            overflow: hidden;
            padding-bottom: 56.25%;
            position: relative;
            height: 0;
            
            iframe {
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
              position: absolute;
            }
          }
          
          .view-larger-map {
            position: absolute;
            bottom: 20px;
            right: 20px;
            background-color: white;
            border: none;
            padding: 8px 16px;
            border-radius: 4px;
          }
        }
      }
    }

.upcoming-events-sidebar {
  width: 100%;
  padding: 20px;
  
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #333;
    
    @media (max-width: 1200px) {
      text-align: center;
    }
  }
  
  .upcoming-event-card {
    text-decoration: none;
    color: inherit;
    display: block;
    margin-bottom: 30px;
    
    @media (max-width: 1200px) {
      max-width: 500px;
      width: 100%;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
    
    .event-card {
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
      }
      
      .event-image-container {
        position: relative;
        width: 100%;
        height: 160px;
        overflow: hidden;
        
        .event-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        
        .recorded-badge {
          position: absolute;
          top: 15px;
          left: 15px;
          background-color: rgba(0, 0, 0, 0.7);
          color: white;
          padding: 6px 12px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          font-size: 14px;
          
          .record-icon {
            margin-right: 6px;
            display: flex;
            align-items: center;
          }
        }
      }
      
      .event-info {
        padding: 16px;
        
        .event-title {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 16px;
          color: #333;
          line-height: 1.3;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        
        .event-details {
          margin-bottom: 20px;
          
          .event-date, .event-location {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            font-size: 14px;
            color: #555;
            
            .icon {
              display: flex;
              align-items: center;
              margin-right: 10px;
            }
            
            span {
              word-wrap: break-word;
              word-break: break-word;
            }
          }
        }
        
        .register-button {
          width: 100%;
          background-color: #7234F7;
          color: white;
          border: none;
          border-radius: 8px;
          padding: 12px;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          transition: background-color 0.2s ease;
          
          &:hover {
            background-color: #6020d0;
          }
        }
      }
    }
  }
  
  .no-upcoming-events {
    padding: 20px;
    text-align: center;
    color: #777;
    font-style: italic;
  }
}

/* Additional responsive styles */
@media (max-width: 600px) {
  .event-detail-page {
    padding: 15px;
    
    .breadcrumb {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
  
  .event-sidebar {
    .sidebar-header {
      .event-title-card h2 {
        font-size: 18px;
      }
    }
  }
  
  .event-main-content {
    .event-header {
      .event-meta {
        gap: 15px;
        
        .date-time, .location {
          .icon {
            width: 35px;
            height: 35px;
          }
          
          .info {
            font-size: 14px;
          }
        }
      }
    }
    
    .registration-section {
      padding: 15px;
      
      h3 {
        font-size: 16px;
      }
      
      p {
        font-size: 14px;
      }
    }
  }
}


}