.header-text-1-tip {
  color: #fe7465 !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.header-text-2-tip {
  color: var(--Filled, #000) !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.wrapper-tip {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  position: relative;
}

.section-1-tip {
  flex: 1;
  height: 80vh;
  border-radius: 16px;
  border: 0.5px solid #a0a0a0;
  background: #fff;
  position: sticky !important;
  top: 100px;

  .header-tip {
    color: var(--Filled, #000) !important;
    text-align: center !important;
    font-family: Outfit !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    margin-top: 10px;
  }

  .content-tip {
    color: var(--Filled, #000);

    font-family: Outfit !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    word-spacing: 3px;
    padding: 8px;
  }

  .content-tip:hover {
    cursor: pointer;
  }
}

.section-2-tip {
  flex: 2;
  background: #fff;
  padding: 10px;
}

.icon-container {
  display: inline-block !important;
  padding: 5px !important;
  border-radius: 8px !important;
  background: #f7f5f9 !important;
  height: 35px !important;
  width: 35px !important;
  background-color: #3e0967 !important;
}

.active-heading {
  background: rgba(114, 52, 247, 0.04);
  color: #3e0967 !important;
}

.icon-container img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
    hue-rotate(0deg) brightness(100%) contrast(100%);
}

.section-2-tip::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(88, 21, 21, 0.3);
  border-radius: 20px;
}

.section-2-tip::-webkit-scrollbar {
  width: 12px;
}

.section-2-tip::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.steps-tip {
  display: flex;
  padding-bottom: 30px !important;
  gap: 20px !important;
  scroll-margin-top: 100px !important;
}

.step {
  color: var(--Filled, #000) !important;
  font-family: Poppins !important ;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
}

.tip-heading {
  color: #3e0967 !important;
  font-family: Outfit !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-bottom: 20px !important;
}

.tip-normal {
  color: var(--Filled, #000) !important;
  text-align: justify !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-bottom: 20px !important;
}

ul li {
  color: var(--Filled, #000);
  text-align: justify !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400;
  line-height: normal !important;
}

ul p {
  color: var(--Filled, #000) !important;
  text-align: justify !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.Dont-tip {
  padding-inline-start: 22px;
}
.Do-tip {
  padding-inline-start: 22px;
}

.section-2-tip::-webkit-scrollbar {
  display: none !important;
}

.progressLine {
  width: 2px;
  height: 99%;
  background-color: #3e0967;
  position: absolute;
  left: 25px;
}

//------------------------------------------------//

.section-3-tip {
  flex: 1;
  height: 50vh;
  border-radius: 16px;
  padding: 20px;
  position: sticky !important;
  top: 100px;
  background: linear-gradient(
    139deg,
    rgba(78, 232, 235, 0.18) 23.35%,
    rgba(114, 52, 247, 0.18) 136.91%
  );
  .header-tip-ad {
    color: var(--Filled, #000) !important;
    font-family: Outfit !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    border-radius: 4px !important;
    border: 1px solid #7234f7 !important;
    padding: 5px 10px;
    display: inline;
  }
  .text-ad {
    color: var(--Filled, #000) !important;
    font-family: Outfit !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }
  .ad-btn {
    color: #4a4a4a !important;
    font-family: Outfit !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    word-spacing: 0.5px;
    letter-spacing: -0.5px;
    text-transform: none;
  }
  .ad-btn-2 {
    color: var(--Filled, #000) !important;
    font-family: Outfit !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    margin-top: 10px;
    text-transform: none;
  }
}

@media (max-width: 900px) {
  .header-text-1-tip {
    font-size: 20px !important;
  }
  .header-text-2-tip {
    font-size: 20px !important;
  }
  .section-1-tip {
    display: none !important;
  }
  .section-3-tip {
    display: none !important;
  }
  .tip-heading {
    font-size: 20px !important;
  }
}
