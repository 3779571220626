.questionsSection{
    margin-top: 8px;
    .title{
         font-family: "Outfit", sans-serif;
        word-wrap: break-word;
        line-height: 60px;
        font-weight: 500;
        text-align: center;
    }
    .MuiAccordionSummary-content{
        margin: 0 0 9px 0!important;
       
    }
    :global(.MuiAccordion-root::before){
       display: none !important;
    }
    
}