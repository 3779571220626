.box-wrapper-1-lastlayer-workshop {
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
}
.box-wrapper-2-lastlayer-workshop {
  padding: 40px;
  margin-top: 10px;
  border-radius: 20px !important;
  background: rgba(81, 71, 199, 0.04) !important;
  display: flex;
}

.text-1-lastlayer-workshop {
  background: linear-gradient(90deg, #7234f7 0%, #fe7465);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: max-content;
  font-family: Outfit !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
}

.text-2-lastlayer-workshop {
  color: var(--Filled, #1C1917) !important;
  font-family: Outfit !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.text-3-lastlayer-workshop {
  color: #2f2f2f !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-top: 30px !important;
}

.text-4-lastlayer-workshop {
  color: #2f2f2f !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding: 20px 40px !important;
}

.wrapper-1-lastlayer-workshop {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  background: #fff;
  display: flex;
  padding: 10px;
  gap: 5px;
  width: 30vw;
}

.text-5-lastlayer-workshop {
  color: #2f2f2f !important;
  font-family: Poppins !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
}

.text-6-lastlayer-workshop {
  color: #2f2f2f !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.card-wrapper-lastlayer-workshop {
  border-radius: 25px !important;
  background: #fafafd !important;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.text-7-lastlayer-workshop {
  color: #7233f7 !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.text-8-lastlayer-workshop {
  color: var(--Filled, #1C1917) !important;
  font-family: Poppins !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.text-9-lastlayer-workshop {
  color: var(--Filled, #1C1917) !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.text-10-lastlayer-workshop {
  color: var(--Filled, #1C1917) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.text-11-lastlayer-workshop {
  color: var(--Filled, #1C1917) !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
