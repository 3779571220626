.courseCardWrapper{
    border-radius: 12px;
    flex-shrink: 0;
    overflow: hidden;
    .imageContainer{
        width: 100%;
        height: 154px;
    }
    .courseContainer{
        padding: 13px 18px 9px 18px;
        background-color:#FFFFFF;
    .placementTag{
        color: #7234F7;
        font-size: 14px;
        font-family: "Outfit", sans-serif;
        font-weight: 400;
        word-wrap: break-word;
    }
    .courseName {
        color: #000000;
        font-family: "Outfit", sans-serif;
        font-size: 16px;
        padding-bottom: 16px;
        font-weight: 700;
        word-wrap: break-word;
    }
    .listContent {
     display: flex;
     padding-bottom: 10px;
     gap: 10px;
     .label{
        color: #000000;
     font-family: "Outfit", sans-serif;
     font-size: 16px;
     line-height: 20px;
     font-weight: 400;
     word-wrap: break-word;
     }}
    }  .buttonContainer{
        .button{
            width: 50%;
            text-transform: none;
            border-radius: 0px;
            color: #0F002E;
            font-family: "Outfit", sans-serif;
            font-weight: 600;
            padding: 18px 10px;
        }
        .learnmore:hover{
            background-color: #d9cef2;
        }
    }
}