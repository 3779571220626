.certificateAwaiting {
  display: flex;
  flex-direction: column; // Ensure vertical stacking on mobile
  overflow: hidden;

  .title1 {
    font-family: "Outfit", sans-serif;
    word-wrap: break-word;
    font-size: 32px;
    font-weight: 500;
    color: #100031;
    line-height: 48px;
    margin-bottom: 16px; // Add space below title
    
  }
  
  .subtitle {
    color: #ff7262;
    font-family: "Outfit", sans-serif;
    word-wrap: break-word;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    margin-bottom: 24px; // Add space below subtitle
  }

  .stepContainer {
    .text1 {
      font-family: "Outfit", sans-serif;
      word-wrap: break-word;
      font-size: 20px;
      font-weight: 700;
    }
    
    .text2 {
      font-family: "Poppins", sans-serif;
      word-wrap: break-word;
      font-size: 14px;
      font-weight: 400;
    }
  }

  @media (max-width: 760px) {
    .title1 {
      font-size: 35px;
      line-height: 0.5;
      margin-left: 55px;
      margin-right: 20px;
      margin-top: 10px;
    }

    .subtitle {
      font-size: 35px;
      line-height: 0.5;
      margin-left: 45px;
      margin-right: 20px;
      margin-bottom: 25px;
       // Ensure enough spacing in mobile view
    }

    .stepContainer {
      .text1 {
        font-size: 16px !important;
      }
    }
  }
}

