.head-text {
  color: #fe7465 !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.top-wrapper-mid2 {
  overflow: hidden;
  width: 100%;
  margin: 25px 0;
}

.company-wrapper {
  animation: animate 20s infinite linear;
}

@keyframes animate {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.normal-text {
  color: var(--Filled, #000) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.bg-register {
  background: var(
    --vc,
    linear-gradient(
      180deg,
      rgba(201, 194, 255, 0) -42.79%,
      rgba(165, 108, 255, 0.08) 100%
    )
  );
}
.reg {
  color: var(--Filled, #000) !important;
  font-family: Outfit !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

@media (max-width: 900px) {
  .head-text {
    font-size: 24px !important;
  }
  .reg {
    font-size: 20px !important;
  }
  .wrapper-reg {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px !important;
  }
  .company-wrapper {
    gap: 30px !important;
  }
}
.company-image {
  width: 100px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}
