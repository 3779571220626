.journeySnapshot {
  background: #0F002E;
  .title,
  .label,
  .description {
    font-family: "Outfit", sans-serif;
    word-wrap: break-word;
  }

  .title {
    color: #FFFFFF;
    line-height: 60px;
    font-weight: 500;
    text-align: center;
  }
  .label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    color : #E7E5E4;
    text-align: left;
  }
  .vertical-timeline-element-icon {
    box-shadow: none !important;
    margin-left: -25px;
    height: 20px;
    width: 20px;
  }
  .vertical-timeline-element-content-arrow{
    display: none;
  }
  .vertical-timeline::before {
    width: 3px;
    left: 12px;
    height: calc(100% - 50px);
  }
  .journey-scroll-container {
    margin: 70px auto;
  }
  .vertical-timeline-element-content p {
    margin: 0;
  }
  .vertical-timeline-element{
    margin: 16px 0px;
  }
  @media only screen and (min-width: 1170px) {
    .vertical-timeline.vertical-timeline--two-columns:before {
      left: calc(50% - 15px);
    }
 

    .journey-scroll-container {
      margin: 100px 170px 60px 170px;
    }
  }
  @media only screen and (min-width: 650px) and (max-width: 1170px) {
    .journey-scroll-container {
      margin: 100px 100px 160px 100px;
    }
  }

  @media (max-width: 1170px) {
  .vertical-timeline-element-icon {
    margin-left: 4px;
  }}
}

