.preloader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .progress-circle {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .logo-svg {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .logo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px; /* Adjust as per logo size */
    height: 30px; /* Adjust as per logo size */
    transform: translate(-50%, -50%);
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .rotating-progress {
    animation: rotate 2s linear infinite;}