.topWrapper-interest {
  padding: 1.5rem 2.5rem;
}

.backBtn-interest {
  color: #fe7465 !important;
  font-family: Outfit !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-bottom: 1.5rem !important;
}

.header-text-interest {
  color: var(--Filled, #000) !important;
  font-family: Outfit !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

.calendarBoxWrapper-interest {
  display: flex !important;
  justify-content: start;
  align-items: center;
  gap: 4px !important;
  margin-top: 1rem !important;
  border-radius: 6px;
  border: 0.5px solid #575757;
  width: fit-content;
  padding: 0.3rem;
}

.durationText-interest {
  color: var(--Filled, #000) !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.subheader-text-interest {
  color: var(--Filled, #000) !important;
  text-align: justify !important;
  font-family: Outfit !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150%;
  margin-top: 1rem !important;
}

.normal-text-interest {
  //   margin-top: 1rem !important;
  color: var(--Filled, #000) !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.mobile-interest {
  display: none !important;
}

.backBtn-mobile-interest {
  border-radius: var(--radius-radius-md, 8px) !important;
  border: 1px solid #8a8a8a !important;
  color: #474646 !important;
  font-family: Outfit !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding: 10px !important;
}

.registration-header-interest {
  color: var(--Filled, #000) !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.registration-normal-interest {
  color: #676767 !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

@media (max-width: 900px) {
  .mobile-interest {
    display: flex !important;
  }
  .desktop-interest {
    display: none !important;
  }

  .header-text-interest {
    font-size: 20px !important;
  }
  .subheader-text-interest {
    font-size: 16px !important;
  }
  .normal-text-interest {
    font-size: 14px !important;
  }
  .mainBtn-interest {
    padding: 10px !important;
    font-size: 14px !important;
  }
  .durationText-interest {
    font-size: 14px !important;
  }
}

.newb {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }

  &:active {
    background-color: #1e7e34;
  }
}

.mainBtn-interest {
  border-radius: 8px !important;
  background: #7234f7 !important;
  color: #fff !important;
  padding: 10px 60px !important;
  font-family: Outfit !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  text-transform: none !important;
  // write code to move this to top right of the screeen
  position: absolute !important;
  top: 150px !important;
  right: 50px !important;
}
