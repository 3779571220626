.testimonialsWrapper {
  text-align: center;
  .testimonialsHeader {
    
    .headText,
    .subText {
      font-family: "Outfit", sans-serif !important;
      word-wrap: break-word;
    }
  }
  
}.contentWrapper {
  display: flex !important;
  justify-content: center;
  .testimonialCard {
    display: flex;
    text-align: left;
    padding: 30px;
    background: #f4f4fe;
    display: flex;
    align-items: center;

    .profileImg {
      width: 62px;
      height: 62px;
      border-radius: 100%;
    }

    .cardContent {
      .name {
        font-family: "Outfit", sans-serif;
        font-weight: 700;
      }

      .tag,
      .testimonial {
        font-weight: 400;
        font-family: "Poppins", sans-serif;
      }

      .tag {
        color: #b4b4b4;
      }
      .name,.tag,.data{
        font-family: "Poppins", sans-serif;
      }
    }
  }
}

@media (min-width: 900px) {
  .contentWrapper {
    .testimonialCard {
      .tag,
      .testimonial,
      .name {
        line-height: 1;
      }
    }
  }
}
