

  .frame {
    position: relative;
    min-height: 200px;
    background-color: #FFFFFF;
    margin: 12px;
    padding: 24px;
    overflow: hidden;
    .imageContainer {
      position: relative;
    }
    .image {
      width: 62px;
      height: 62px;
      object-fit: cover;
      transition: opacity 0.3s ease;
    }
    
    .label {
      color: #100031;
      font-family: "Outfit", sans-serif;
      font-weight: 600;
      word-wrap: break-word;
    }
    .subText {
      color: #272727;
      font-family: "Outfit", sans-serif;
      font-size: 14px !important;
      font-weight: 400;
      word-wrap: break-word;
      line-height: 1.2;
      width: calc(100% - 100px);
    }
    @media (max-width:600px) {
      .subText,.label{
        width: 100%;
      }
    }


  }