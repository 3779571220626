.wrapper-top-last {
  display: flex;
  padding: 20px 10px;
  background: linear-gradient(
    180deg,
    rgba(201, 194, 255, 0) 11.85%,
    rgba(165, 108, 255, 0.08) 100%
  );
  overflow: hidden;
}

.wrapper-1-last {
  flex: 1 !important;
  position: relative;
  padding: 20vh 0;
}

.wrapper-2-last {
  flex: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.header-text-last {
  color: var(--Filled, #fe7465) !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.normal-text-last {
  color: var(--Filled, #000) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.btn-last {
  border-radius: 6px !important;
  background: #7234f7 !important;
  color: #fff !important;
  font-family: Outfit !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-top: 10px !important;
  text-transform: none !important;
}

.btn-last:hover {
  background-color: #7234f7 !important;
}

.firstImage-last {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 0.545px solid rgba(0, 0, 0, 0.05);
  background: var(--Black-800, #111);
  width: 200px !important;
  height: 70px !important;
  padding: 5px !important;
  gap: 5px !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5%;
  top: 12%;
  z-index: 2;
}

.firstImage-line-last {
  width: 80%;
  height: 3px;
  background: #2c2c2c;
}

.av-i {
  width: 15px !important;
  height: 15px !important;
}

.firstImage-text-last {
  color: #fff !important;
  font-feature-settings: "liga" off, "clig" off !important;
  font-family: Poppins !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 10.904px !important;
}

.secondImage-last {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.IIndimage-last {
  width: 100%;
  height: auto;
  z-index: 1 !important;
  position: relative;
}

.thirdImage-last {
  position: absolute;
  bottom: 0;
  left: 10%;
  z-index: 1 !important;
}

.IIIrdImage-last {
  width: 100%;
  height: auto;
}

.rightWing-last {
  position: absolute;
  top: -85%;
  right: -100%;
}

.leftWing-last {
  position: absolute;
  top: -85%;
  left: -125%;
}

@media (max-width: 900px) {
  .firstImage-text-last {
    font-size: 8px !important;
  }
  .wrapper-top-last {
    flex-direction: column-reverse !important;
    gap: 20px !important;
  }
  .header-text-last {
    font-size: 20px !important;
  }
  .normal-text-last {
    font-size: 14px !important;
  }
  .firstImage-last {
    height: 55px !important;
    width: 160px !important;
  }
}
