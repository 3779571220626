.filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 100%;
  // padding: 16px;
  font-family: Poppins;
  // gap: 0px 12px;
  flex-wrap: nowrap;
 

  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin-top: -8%;
    margin-bottom: -8%;
  }

  @media (max-width: 1200px) {
    padding: 20px;
    
  }

  @media (max-width: 375px) { 
    // padding: 8px;
    gap: 0px 8px;
    margin-top: -5%;
    margin-bottom: -5%;
  }
}

.search-bar-wrapper {
  display: flex;
  align-items: center;
  display: flex;
  border-radius: var(--radius-radius-md, 8px);
  background: #FFF;
  width: 374px;
  height: 50px;
  padding: 16px var(--Spacing-space-10, 24px);
  align-items: center;
  gap: var(--Spacing-space-10, 24px);
  flex-shrink: 0;
  font-family: Poppins;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.search-bar {
  
  // flex-grow: 1;
  // background-color: white;
  // font-family: Poppins;
  // max-width: 100%; /* Ensure search bar takes up full width */
  // // margin-right: 10px;
  // padding: 0px 8px 8px 0px;

  @media (max-width: 768px) {
    width: calc(100% - 48px); /* Mobile view adjustments */
  }

  @media (max-width: 375px) {
    width: calc(100% - 40px); /* Further adjust for smaller screens */
    // margin-right: 8px;
  }
}

.image-filter{
  background-color: white;
  padding: 10px;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  align-items: center;
}
.imgg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0px 0px 8px ;
  height: 50px;
  background-color: white;
}
.img-search {
  width: var(--Icon-icon-lg, 24px);
  height: var(--Icon-icon-lg, 24px);
  flex-shrink: 0;
  background-color: white;
  // margin-right: 10px;

  @media (max-width: 375px) { /* Reduce padding for smaller screens */
    padding: 6px;
  }
}

.filter-icon {
  // padding: 8px;
  // margin-left: 8px;
  padding: 0;
  background-color: transparent;
  border: 0px transparent ;


  @media (min-width: 768px) {
    display: none; /* Hide filter icon on larger screens */
  }

  @media (max-width: 375px) { /* Adjust icon padding for smaller screens */
    // padding: 6px;
    padding: 0;
  }
}

.filter-group {
  display: flex;
  gap: 16px;
  align-items: center;

  @media (max-width: 1200px) {
    display: none;
  }

  // @media (max-width: 768px) {
  //   flex-direction: column;
  //   width: 100%;
  // }

  // @media (max-width: 375px) { 
  //   gap: 8px;
  // }
}

.filter-dropdown {
  display: flex;
  width: 157px;
  height: 50px;
  padding: 16px 21px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: white;
  font-family: Poppins;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 8px;
  }

  @media (max-width: 375px) { 
    min-width: 100%;
    margin-bottom: 8px;
  }
}

.disp-fil {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--Neautral-600, #78716C);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  font-family: Poppins;
  padding: 16px;

  @media (max-width: 375px) {
    padding: 12px; 
  }
}

.filter-content {
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  gap: 10px;
  padding: 16px;

  @media (max-width: 375px) {
    padding: 12px;
    gap: 8px;
  }
}

.desktop-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins;
  @media (max-width: 768px) {
    display: none;
  }
  // @media (min-width: 768px) {
  //   gap: 10px;
  //   flex-grow: 1;
  //   justify-content: flex-start;
  // }
}

