
.coursesCatalogWrapper{
   
    .MuiPaginationItem-page.Mui-selected {
        color: #000000 !important;}
    .sectionWrapper{
        display: flex;
        .cardsDiv{
            display:flex ;
            flex-direction: column;
        }

    }
} 
.helperText{
    color: #000;
    font-family: "Outfit", sans-serif;
    font-weight: 500 !important;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom:16px ;
}