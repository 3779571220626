.header-join {
  text-align: center !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  padding: 20px 0 !important;
  font-family: Outfit !important;
}

@media (max-width: 900px) {
  .wrapper-1 {
    flex-direction: column !important;
  }
  .btn {
    justify-content: center !important;
    margin-top: 20px;
  }
  .free {
    width: 80px !important;
  }
  .header-join {
    font-size: 24px !important;
  }
  .img-join {
    width: 200px !important;
    height: auto !important;
  }
  .avg-G .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  .avg-i {
    width: 10px;
    height: 10px;
  }
}
