// // .companiesSection {
// //   text-align: center;
// //   position: absolute;
// //   bottom: -270px; /* Moves the logos to the bottom of the parent container */
// //   left: 0;
// //   right: 0;

// //   .headText {
// //     padding: 14px 12px;
// //     color: #000;
// //     font-family: "Outfit", sans-serif;
// //     font-weight: 300;
// //     word-wrap: break-word;
// //     margin-bottom: 10px;
// //     background-color: #fff;
// //     display: inline-flex;
// //     align-items: center;
// //     justify-content: center;
// //     border-radius: 28px;
// //     font-size: 15px;
// //     white-space: nowrap;
// //     line-height: 1.5;

// //     /* Limiting the width on mobile */
// //     max-width: 90%;  /* Limit to 90% of the viewport width */
// //     margin: 0 auto; /* Center it horizontally */

// //     .line {
// //       height: 2px;
// //       margin: 0 15px;
// //       width: 50px; /* Default line length */
// //       background-color: #000;
// //     }

// //     .line:first-of-type {
// //       margin-right: 15px !important;
// //     }

// //     .line:last-of-type {
// //       margin-left: 15px !important;
// //     }

// //     /* Responsiveness */
// //     @media (max-width: 1200px) {
// //       font-size: 14px; /* Slightly smaller text for tablets */
// //       padding: 12px 10px;
// //     }

// //     @media (max-width: 900px) {
// //       font-size: 13px; /* Smaller font for medium screens */
// //       padding: 10px 8px;
// //       .line {
// //         height: 0.1px !important; /* Slightly thinner line on medium screens */
// //       }
// //     }

// //     @media (max-width: 600px) {
// //       font-size: 12px !important; /* Further decrease font size for mobile */
// //       padding: 8px 6px;
      
// //       /* Adjust width of the lines for mobile */
// //       .line {
// //         width: 30px; /* Shorter line on mobile */
// //       }
// //     }
// //   }

// //   .companiesSlider {
// //     width: 100%;
// //     overflow: hidden;
// //     white-space: nowrap;
// //     position: relative;

// //     .innerSlider {
// //       display: flex;
// //       animation: slide 25s infinite linear;

// //       .logoContainer {
// //         display: inline-block;
// //         img {
// //           width: 100%;
// //         }
// //       }
// //     }

// //     /* Left blur effect */
// //     &::before {
// //       content: "";
// //       position: absolute;
// //       top: 0;
// //       left: 0;
// //       width: 120px; /* Width of the blur effect */
// //       height: 100%;
// //       background: linear-gradient(
// //         to right,
// //         rgb(255, 255, 255),
// //         rgba(255, 255, 255, 0)
// //       );
// //       z-index: 1;
// //       pointer-events: none;
// //       filter: blur(1px); /* The blur effect */
// //     }

// //     /* Right blur effect */
// //     &::after {
// //       content: "";
// //       position: absolute;
// //       top: 0;
// //       right: 0;
// //       width: 120px; /* Width of the blur effect */
// //       height: 100%;
// //       background: linear-gradient(
// //         to left,
// //         rgba(255, 255, 255),
// //         rgba(255, 255, 255, 0)
// //       );
// //       z-index: 1;
// //       pointer-events: none;
// //       filter: blur(1px); /* The blur effect */
// //     }

// //     @keyframes slide {
// //       0% {
// //         transform: translateX(0%);
// //       }
// //       100% {
// //         transform: translateX(-50%);
// //       }
// //     }

// //     @media (max-width: 900px) {
// //       padding-bottom: 60px;

// //       &::before {
// //         width: 50px;
// //       }
// //       &::after {
// //         width: 50px;
// //       }
// //     }
// //   }
// // }
// .companiesSection {
//   text-align: center;
//   position: absolute;
//   bottom: -270px; /* Moves the logos to the bottom of the parent container */
//   left: 0;
//   right: 0;

//   .headText {
//     padding: 14px 12px;
//     color: #000;
//     font-family: "Outfit", sans-serif;
//     font-weight: 300;
//     word-wrap: break-word;
//     margin-bottom: 10px;
//     background-color: #fff;
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 28px;
//     font-size: 15px;
//     white-space: nowrap;
//     line-height: 1.5;

//     /* Limiting the width on mobile */
//     max-width: 90%;  /* Limit to 90% of the viewport width */
//     margin: 0 auto; /* Center it horizontally */

//     .line {
//       height: 2px;
//       margin: 0 15px;
//       width: 50px; /* Default line length */
//       background-color: #000;
//     }

//     .line:first-of-type {
//       margin-right: 15px !important;
//     }

//     .line:last-of-type {
//       margin-left: 15px !important;
//     }

//     /* Responsiveness */
//     @media (max-width: 1200px) {
//       font-size: 14px; /* Slightly smaller text for tablets */
//       padding: 12px 10px;
//     }

//     @media (max-width: 900px) {
//       font-size: 13px; /* Smaller font for medium screens */
//       padding: 10px 8px;
//       .line {
//         height: 0.1px !important; /* Slightly thinner line on medium screens */
//         width: 40px; /* Adjust the line width to be more appropriate */
//       }
//     }

//     @media (max-width: 600px) {
//       font-size: 12px !important; /* Further decrease font size for mobile */
//       padding: 8px 6px;
      
//       /* Adjust width of the lines dynamically for mobile */
//       .line {
//         width: 60px; /* Increased width for better visibility */
//       }
//     }
//   }

//   .companiesSlider {
//     width: 100%;
//     overflow: hidden;
//     white-space: nowrap;
//     position: relative;

//     .innerSlider {
//       display: flex;
//       animation: slide 25s infinite linear;

//       .logoContainer {
//         display: inline-block;
//         img {
//           width: 100%;
//         }
//       }
//     }

//     /* Left blur effect */
//     &::before {
//       content: "";
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 120px; /* Width of the blur effect */
//       height: 100%;
//       background: linear-gradient(
//         to right,
//         rgb(255, 255, 255),
//         rgba(255, 255, 255, 0)
//       );
//       z-index: 1;
//       pointer-events: none;
//       filter: blur(1px); /* The blur effect */
//     }

//     /* Right blur effect */
//     &::after {
//       content: "";
//       position: absolute;
//       top: 0;
//       right: 0;
//       width: 120px; /* Width of the blur effect */
//       height: 100%;
//       background: linear-gradient(
//         to left,
//         rgba(255, 255, 255),
//         rgba(255, 255, 255, 0)
//       );
//       z-index: 1;
//       pointer-events: none;
//       filter: blur(1px); /* The blur effect */
//     }

//     @keyframes slide {
//       0% {
//         transform: translateX(0%);
//       }
//       100% {
//         transform: translateX(-50%);
//       }
//     }

//     @media (max-width: 900px) {
//       padding-bottom: 60px;

//       &::before {
//         width: 50px;
//       }
//       &::after {
//         width: 50px;
//       }
//     }
//   }
// }
.companiesSection {
  text-align: center;
  position: absolute;
  bottom: -270px; /* Moves the logos to the bottom of the parent container */
  left: 0;
  right: 0;

  .headText {
    padding: 14px 12px;
    color: #000;
    font-family: "Outfit", sans-serif;
    font-weight: 300;
    word-wrap: break-word;
    margin-bottom: 10px;
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    font-size: 15px;
    white-space: nowrap;
    line-height: 1.5;

    /* Limiting the width on mobile */
    max-width: 90%;  /* Limit to 90% of the viewport width */
    margin: 0 auto; /* Center it horizontally */

    .line {
      height: 1px; /* Reduced line height */
      margin: 0 10px; /* Reduced margin */
      width: 60px; /* Default line length */
      background-color: #000;
    }

    .line:first-of-type {
      margin-right: 10px !important;
    }

    .line:last-of-type {
      margin-left: 10px !important;
    }

    /* Responsiveness */
    @media (max-width: 1200px) {
      font-size: 14px; /* Slightly smaller text for tablets */
      padding: 12px 10px;
    }

    @media (max-width: 900px) {
      font-size: 13px; /* Smaller font for medium screens */
      padding: 10px 8px;
      .line {
        height: 1.4px !important; /* Thinner line for smaller screens */
        width: 2px; /* Adjust the line width for better visibility */
      }
    }

    @media (max-width: 600px) {
      font-size: 12px !important; /* Further decrease font size for mobile */
      padding: 8px 6px;
      
      /* Adjust width of the lines dynamically for mobile */
      .line {
        width: -10px !important; /* Reduced width for better visibility on small screens */
      }
    }
  }

  .companiesSlider {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;

    .innerSlider {
      display: flex;
      animation: slide 25s infinite linear;
padding-top: 16px;
      .logoContainer {
        display: inline-block;
        img {
          width: 100%;
        }
      }
    }

    /* Left blur effect */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 120px; /* Width of the blur effect */
      height: 100%;
      background: linear-gradient(
        to right,
        rgb(255, 255, 255),
        rgba(255, 255, 255, 0)
      );
      z-index: 1;
      pointer-events: none;
      filter: blur(1px); /* The blur effect */
    }

    /* Right blur effect */
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 120px; /* Width of the blur effect */
      height: 100%;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255),
        rgba(255, 255, 255, 0)
      );
      z-index: 1;
      pointer-events: none;
      filter: blur(1px); /* The blur effect */
    }

    @keyframes slide {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-50%);
      }
    }

    @media (max-width: 900px) {
      padding-bottom: 60px;

      &::before {
        width: 50px;
      }
      &::after {
        width: 50px;
      }
    }
  }
}
