.checkout {
  .title {
    font-size: 20px;
    font-weight: 400;
    font-family: "Outfit", sans-serif;
  }
  .contentWrapper {
    display: flex;
    justify-content: space-between;

    .leftDiv {
      .courseOrdered {
        display: flex;
        .point1,
        .point2,
        .courseName {
          font-family: "Outfit", sans-serif;
          line-height: 1;
        }
      }
      .courseName {
        font-size: 20px;
      }
      .billingOrder {
        border-radius: 9px;
        max-width: 900px;
        border: 1px solid #d0d0d0;
        .formDetails {
          display: flex;
        }
      }
      .formInputHeader {
        color: var(--G400, #9794aa);
        font-family: "Outfit", sans-serif;
        font-size: 16px;
        font-weight: 400;
        padding: 18px 0 8px 0;
        line-height: 1;
      }
      .MuiOutlinedInput-root {
        height: 48px;
      }
      .billingHead {
        font-size: 20px;
        font-weight: 400px;

        font-family: "Outfit", sans-serif;
      }
    }
    .rightDiv {
      .paymentHead {
        font-size: 22px;
        font-family: "Outfit", sans-serif;
        line-height: 1;
        font-weight: 500;
        align-self: center;
      }
      .paymentDetails {
        background-color: #ffffff;
        border: 1px solid rgba(204, 204, 204, 0.5);
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Adds a subtle shadow
      }
      .paymentDetails > * {
        color: #000000;
        font-family: "Outfit", sans-serif;
      }
      .payBtn {
        box-shadow: none;
        width: 100%;
        background-color: #7234f7;
        color: #ffffff;
        text-transform: none;
        border-radius: 8px;
        height: 50px;
        font-size: 16px;
        &.payBtnDisabled{
        background-color: #7234f7;
        color: #ffffff;
        &:hover{
          cursor:not-allowed;
        }
        }
       
      }
      .feeHead {
        font-size: 28px;
        padding-bottom: 18px;
        font-weight: 500;
      }
      .cp {
        font-size: 24px;
        padding-bottom: 28px;
        font-weight: 400;
      }
      .sellingPrice {
        font-size: 32px;
        padding-bottom: 6px;
        font-weight: 500;
        line-height: 1;
      }
      .offerTag {
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        padding-bottom: 6px;
      }
      .gst {
        font-weight: 300;
        font-size: 12px;
        padding-bottom: 40px;
      }
      .terms {
        font-weight: 300;
        align-self: center;
        font-size: 14px;
        line-height: 1;

        font-family: "Outfit", sans-serif;
      }
    }
  }
  .MuiTextField-root {
    width: 100%;
  }
  .MuiOutlinedInput-root {
    font-size: 16px;
    color: #686677;
    font-weight: 500;
    font-family: "Outfit", sans-serif;
  }
  
  .MuiLink-root{
    text-decoration-color: #ffffff;
  }

  @media (max-width: 900px) {
    .formInputHeader {
      font-weight: 12px;
    }
    .point1,
    .point2 {
      font-weight: 12px;
    }
    .courseName {
      font-size: 16px;
    }
    .paymentHead {
      font-size: 20px;
    }
    .feeHead {
      font-size: 20px;
      padding-bottom: 21px !important;
    }
  }
  .cp {
    font-size: 16px;
    padding-bottom: 20px !important;
  }
  .sellingPrice {
    font-size: 24px;
    padding-bottom: 7px;
  }
  .offerTag {
    line-height: 1;
    padding-bottom: 23px !important;
  }
  .gst {
    padding-bottom: 32px !important;
  }
  .terms {
    font-size: 12px;
  }
}
