.subscribeWrapper {
  display: flex;
  background-color: #0f002e;
  position: relative;
  .subscribeContent {
    width: 70%;
    .tagLine {
      color: #ffffff;
      font-weight: 700;
      font-family: "Outfit", sans-serif;
      word-break: break-word;
      padding-bottom: 25px;
    }

    .subscribeBtn {
      background-color: #fe7465;
      color: #ffffff;
      margin-left: 10px;
      text-transform: none;
      padding: 12px 18px;
      border-radius: 3px;
      margin-bottom: 10px;
    }
  }
}
