.wrapper-top-workshop-toplayer {
  //   height: 100vh;
  //   width: 100vw;
  //   background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.header-text-1-workshop {
  color: var(--Filled, #000) !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-align: center !important;
  width: 700px !important;
  margin-top: 46px !important;
}

.header-text-2-workshop {
  background: linear-gradient(90deg, #7233f7 34.56%, #ff7262 91.81%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.watermark1-workshop {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  width: 200px !important;
  height: 240px !important;
}

.waterMark2-workshop {
  position: absolute !important;
  left: -35px;
  bottom: 0;
  width: 200px;
  height: 240px;
}

.text-1-workshop {
  color: var(--Filled, #000) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 800px !important;
  margin-top: 24px !important;
}

.text-2-workshop {
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.text-3-workshop {
  position: relative;
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  z-index: 1 !important;
}

.text-4-workshop {
  background: linear-gradient(90deg, #bd89ff 21.41%, #fff 141.89%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-family: Poppins !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.box-subwrapper-1-workshop {
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.87);
  background: #2b2d2c;
  padding: 11px;
}

.btn-topLayer-1-workshop {
  border-radius: 6px !important;
  border: 0.5px solid rgba(255, 255, 255, 0.4) !important;
  width: 100%;
  gap: 5px;
}

.btn-2-workshop-topLayer {
  color: #fff !important;
  font-family: Outfit !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.btn-3-workshop-topLayer {
  color: #fff !important;
  font-family: Outfit !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border-radius: 8px !important;
  background: linear-gradient(90deg, #7233f7 0%, #bd89ff 116.37%);
  text-transform: none !important;
  padding: 13px !important;
  margin-top: 20px !important;
}

.text-5-workshop {
  color: var(--Filled, #000) !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-top: 20px !important;
}
