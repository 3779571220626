.header-text-top {
  font-size: 40px !important;
  font-weight: 500 !important;
  font-family: Outfit !important;
  line-height: normal !important;
}

.no_ {
  color: #7234f7 !important;
  font-family: Poppins !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.text_ {
  color: var(--Filled, #000) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding-top: 10px;
}

.gradient-text {
  background: linear-gradient(90deg, #7234f7 0%, #fe7465 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.gradient-button {
  display: flex;
  justify-content: center;
  width: 233px;
  height: 50px;
  color: white !important;
  background: linear-gradient(90deg, rgba(114, 52, 247, 0.89) 0%, rgba(254, 116, 101, 0.9) 100%);
  z-index: 1;
  text-transform: none !important;
}

.adv-box {
  display: flex;
  flex-direction: column;
  width: 116px;
  height: 93px;
  gap: 10px;

}

.adv-text {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal !important;
}

.img {
  width: 250px !important;
  height: 250px !important;
}

// .gradient-background {
//   padding: 70px 0;
//   position: relative;
//   /* Soft pink/purple gradient background */
//   background: linear-gradient(135deg,
//     rgb(255, 245, 255) 0%,     /* Light pink/white in center */
//     rgba(255, 230, 255, 0.8) 20%,
//     rgba(248, 240, 255, 0.9) 50%,
//     rgba(240, 230, 255, 0.8) 80%,
//     rgba(235, 225, 255, 0.9) 100%
//   );
// }
.gradient-background {
  padding : 70px 0;
  background-image: url("../../../assets/images/mockInterview/CCBG.svg");
  background-size: cover;
  background-position: center;
  // Ensure it's behind other elements
  position: relative;
  height: 100vh;  // Adjust height as needed
}

// Additional styles for other elements

/* Add subtle corner gradients */
.gradient-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(
      circle at top left,
      rgba(255, 230, 255, 0.4) 0%,
      transparent 60%
    ),
    radial-gradient(
      circle at bottom right,
      rgba(240, 230, 255, 0.4) 0%,
      transparent 60%
    );
  pointer-events: none;
}

.job_ {
  padding: 14px;
  z-index: 1;
}

.wrapper-small-1 {
  flex: 1 !important;
  border-radius: 14px !important;
  background: var(--vc, linear-gradient(90deg, #7234f7 0%, #bd89ff 100%));
  padding: 6px !important;
  z-index: 1;
}

.wrapper-small-2 {
  flex: 1 !important;
  border-radius: 14px !important;
  border: 1px solid #e6e6e6 !important;
  background-color: white;
  padding: 6px !important;
  z-index: 1;
}

.arrow {
  width: 34px !important;
  height: auto !important;
}

.bag {
  width: 34px !important;
  height: auto !important;
}

.secondOutsideText {
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.secondInsideText {
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.thirdOutsideText {
  color: #7234f7 !important;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}
.thirdInsideText {
  color: var(--Filled, #000);
  font-family: Poppins !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}





@media (max-width: 900px) {
  .header-text-top {
    font-size: 24px !important;
  }
  .wrapper-box-1 {
    flex-direction: column;
  }
  .wrapper-box-2 {
    justify-content: center;
    align-items: center;
    p {
      text-align: center;
    }
  }
  .wrapper-box-3 {
    flex-direction: column;
    margin-left:62px
  }
  .adv-box {
    flex-direction: row;
    width: 265px;
    height: 37px;
    justify-content: left;
    align-items: left;
  }
  .watermark-top {
    display: none !important;
  }
  .img {
    width: 150px !important;
    height: auto !important;
  }
  .job_wrapper {
    top: 12px !important;
    left: 12px !important;
  }
  .job_ {
    width: 24px !important;
    height: 24px !important;
    padding: 4px !important;
  }
  .text_ {
    font-size: 10px !important;
    line-height: 1.2 !important;
    font-size: 8px !important;
    padding-bottom: 10px;
  }
  .no_ {
    font-size: 20px !important;
    top: 63px !important;
    left: 53px !important;
  }
  .curve-1 {
    width: 70px !important;
    height: auto !important;
    top: 40px !important;
    left: 24px !important;
  }
  .curve-2 {
    width: 27px !important;
    height: auto !important;
    top: 48px !important;
    left: 92px !important;
  }
  .secondOutsideText {
    font-size: 17px !important;
    line-height: 1 !important;
  }
  .secondInsideText {
    font-size: 8px !important;
    line-height: 1.2 !important;
  }
  .thirdOutsideText {
    font-size: 17px !important;
    line-height: 1 !important;
  }
  .thirdInsideText {
    font-size: 8px !important;
    line-height: 1.2 !important;
  }

  .arrow {
    width: 24px !important;
    height: auto !important;
  }
  .bag {
    width: 24px !important;
    height: auto !important;
  }
}

.iframe-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  z-index: 1000;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 80%; /* Adjust width as needed */
  height: 80%; /* Adjust height as needed */
}

.iframe-popup iframe {
  width: 100%;
  height: 100%;
}

.iframe-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}