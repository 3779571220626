.hero-container {
  position: relative;
  width: 100%;
  height: 88vh;
  overflow: hidden;
}

.hero-image-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  object-position: 40%;
  top: 0;
  left: 0;
  z-index: -1;
}

.header-text-wrapper-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
}

.header-text-subwrapper-top {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.header-text-1-top {
  color: var(--Filled, #fff) !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 34px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.header-text-2-top {
  color: #fff !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  border-radius: 60px !important;
  background: rgba(255, 255, 255, 0.09) !important;
  backdrop-filter: blur(13.899999618530273px) !important;
  padding: 8px 16px !important;
}

.header-text-3-top {
  color: var(--Filled, #fff) !important;
  font-family: Outfit !important;
  font-size: 34px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.normal-text-top {
  color: #fff !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 724px;
  margin-top: 5px !important;
}

.gradient-bg {
  position: absolute !important;
  bottom: 0 !important;
  height: 30% !important;
  width: 100% !important;
  background: linear-gradient(
    0deg,
    rgba(121, 68, 189, 0.3) 20%,
    rgba(121, 68, 189, 0.2) 70%,
    rgba(121, 68, 189, 0) 100%
  );
}

.btn-top {
  padding: 10px 36px !important;
  color: white !important;
  border-radius: 6px !important;
  background: linear-gradient(90deg, #7234f7 0%, #fe7465 100%) !important;
  margin-top: 15px !important;
  font-family: Outfit !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: none !important;
}

@media (max-width: 900px) {
  .header-text-subwrapper-top {
    flex-direction: column !important;
    gap: 10px !important;
  }
  .header-text-1-top {
    font-size: 24px !important;
  }
  .header-text-2-top {
    font-size: 22px !important;
  }
  .header-text-3-top {
    font-size: 24px !important;
  }
  .normal-text-top {
    font-size: 20px !important;
    width: auto !important;
    padding: 15px 10px !important;
    font-weight: 200 !important;
  }
  .hero-container {
    height: 95vh;
  }
}
