.testimonialsWrapper {
  background-color: #f3f4fd;
  padding: 50px 0;
  padding-top: 0px;
}

.testimonialsHeader {
  margin-bottom: 30px;
}

.headText {
  font-weight: 600;
  color: #333;
}

.subText {
  font-weight: 700;
  color: #333;
}

@keyframes slideVerticalUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: rgba(255, 255, 255, 0);
  white-space: nowrap;
  position: relative;
}
.logos-slide {
  display: inline-block;
  animation: 8s slideVerticalUp infinite linear;
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
}

@keyframes scrollVerticalDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.logos-1 {
  overflow: hidden;
  padding: 60px 0;
  background: rgba(6, 1, 1, 0);
  white-space: nowrap;
  position: relative;
  margin-left: 10px;
}
.logos-slide-1 {
  display: inline-block;
  animation: 8s scrollVerticalDown infinite linear;
}

.logos-slide-1 img {
  // height: 50px;
  margin: 0 10px;
}

@keyframes scrollHorizontal {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .logos {
    padding: 10px 0; /* Reduce padding */
    background: rgba(
      211,
      211,
      211,
      0
    ); /* Change background for smaller screens */
  }

  .logos-slide {
    animation: 8s slide infinite linear reverse; /* Speed up the animation for smaller screens */
  }

  .logos-slide img {
    height: 30px; /* Make images smaller */
    margin: 0 20px; /* Reduce spacing between images */
  }
}

@media (max-width: 768px) {
  .logos-1 {
    padding: 10px 0; /* Reduce padding */
    background: rgba(
      211,
      211,
      211,
      0
    ); /* Change background for smaller screens */
    margin-left: 0px;
  }

  .logos-slide-1 {
    animation: 8s slide infinite linear; /* Speed up the animation for smaller screens */
  }

  .logos-slide-1 img {
    //   height: 30px; /* Make images smaller */
    margin: 0 20px; /* Reduce spacing between images */
  }
}
