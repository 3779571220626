.header-text-key {
  color: #fe7465 !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  margin-bottom: 56px !important;
}

.wrapper-key {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
}
.wrapper-key-inside {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #7234f76b;
  padding: 24px;
}

.normal-text-key {
  color: var(--Filled, #000) !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.wi-1 {
  justify-content: start !important;
}

.wi-2 {
  justify-content: start !important;
}

.w1 {
  width: 40vw;
}

.w2 {
  width: 45vw;
}

@media (max-width: 900px) {
  .header-text-key {
    font-size: 24px !important;
  }
  .wrapper-key {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .w1 {
    width: 75vw;
  }
  .w2 {
    width: 75vw;
  }
  .wi-1 {
    justify-content: start !important;
  }
}
