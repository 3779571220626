.cookie-consent-container {
  width: 100%;
  max-width: 100%;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  
  .cookie-consent {
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
    
    .cookie-text {
      margin-bottom: 16px;
      text-align: center;
      
      @media (min-width: 768px) {
        margin-bottom: 0;
        margin-right: 24px;
        text-align: left;
      }
      
      .privacy-link {
        color: #7234F7;
        margin-left: 8px;
        text-decoration: underline;
      }
    }
    
    .cookie-buttons {
      display: flex;
      gap: 16px;
      
      .decline-button {
        color: white;
        border-color: white;
        
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
      
      .accept-button {
        background-color: #7234F7;
        
        &:hover {
          background-color: #5a29c5;
        }
      }
    }
  }
}