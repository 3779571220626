.box-wrapper-1-thirdLayer-workshop {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  padding: 20px 30px;
  align-self: center;
  justify-items: center;
  justify-content: center;
  margin-top: 20px !important;
}

.box-wrapper-2-thirdLayer-workshop {
  border-radius: 14px;
  border: 0.5px solid #c3c3c3;
  background: #fff;
  padding: 16px;
  gap: 10px;
}

.box-wrapper-3-thirdLayer-workshop {
  border-radius: 14px;
  border: 0.5px solid #c3c3c3;
  background: #fff;
  padding: 16px;
  gap: 10px;
}

.box-wrapper-4-thirdlayer-workshop {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 30px;
  align-items: stretch !important;
  grid-auto-rows: 1fr !important;
  padding: 0 40px;
}

.box-wrapper-5-thirdlayer-workshop {
  border-radius: 10px !important;
  border: 1px solid var(--style, #7233f7) !important;
  background: #fff !important;
  padding: 14px 40px 14px 24px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: start !important;
  justify-content: center;
  position: relative !important;
}

.text-1-thirdlayer-workshop {
  background: var(
    --vibrant,
    linear-gradient(120deg, #7234f7 0%, #fe7465 100%)
  ) !important;
  background-clip: textv !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  width: max-content;
  font-family: Outfit !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: none !important;
}

.text-2-thirdlayer-workshop {
  color: #2f2f2f !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-align: start !important;
  text-transform: none !important;
}

.btn-thirdLayer-workshop {
  border-radius: var(--radius-radius-md, 8px);
  border: 1px solid #7233f7 !important;
  text-transform: none !important;
  color: #7234f7 !important;
  font-family: Outfit !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding: 20px !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
