.finderWrapper {
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;



    .overlayWrapper {
        background: rgba(0, 0, 0, 0.47);
        text-align: center;
        max-width: 900px;

        .text1,
        .text2 {
            color: #FFFFFF;
            font-family: "Outfit", sans-serif;
            word-wrap: break-word;
            line-height: 1.2;
        }

        .text1 {
            font-weight: 500;
        }

        .text2 {
            font-weight: 200;
        }
    }

    .stickyContainer {
        position: fixed;
        top: 40px;
        z-index: 10;
        left: 0;
        background-color: #F3F4FD;
        padding: 23px 16px 16px 16px;
        width: 100%;
    }

    .searchWrapper {
        position: relative;

        .promptList {
            width: 100%;
            background-color: #FFFFFF;
            display: flex;
            z-index: 10;
            flex-direction: column;
            border-radius: 10px;
            overflow-y: auto;
            text-align: left;
            border-radius: 0 0 8px 8px;
            position: absolute;
            top: 90%;
            left: 0;

            .searchResult {
                padding: 10px 20px;
                .promptText{
                    font-family: "Outfit", sans-serif;
                    word-wrap: break-word;
                    line-height: 1.2;
                    font-size: 14px;
                }
            }

            .searchResult:hover {
                background-color: #EFEFEF;
            }

            .searchResult:has(.cvloader) {
                display: flex;
                justify-content: center;
            }
        }
    }

}