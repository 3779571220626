.events-main{
    background-color: #F3F4FD;
}
.events-page {
    font-family: 'Outfit';
    max-width: 1400px;
    margin: 0 auto;
    
    .hero-section2 {
      // Remove the background URL since we're using a video element
      position: relative;
      border-radius: 20px;
      padding: 110px 70px;
      margin-bottom: 25px;
      margin-top: 25px;
      overflow: hidden;
      
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
      }
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 1;
      }
      
      .eventHero-content {
        position: relative;
        z-index: 1;
        color: #fff;
        max-width: 800px;
        
        h1 {
          font-size: 80px;
          font-weight: 700;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          margin-bottom: 1px;
          margin-top: 0px;
        }
        
        h2 {
          font-size: 70px;
          font-weight: 500;
          margin-top: 1px;
          margin-bottom: 1px;
        }
        
        p {
          font-size: 20px;
          line-height: 1.5;
          margin-top: 5px;
          margin-bottom: 20px;
        }
        
        .search-bar {
          position: relative;
          max-width: 470px;
          
          input {
            width: 100%;
            padding: 14px 20px;
            padding-right: 160px;
            border-radius: 100px;
            background-color: rgba(255, 255, 255, 0.10);
            color: #fff;
            border: 1px solid #fff;
            outline: none;
            font-size: 16px;
            &::placeholder {
              color: rgba(255, 255, 255, 0.8);
            }
          }
          
          .search-button {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            background: linear-gradient(270deg, #5911F3 0%, #F43D29 100%);
            gap: 6px;
            align-items: center;
            justify-content: center;
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 100px;
            cursor: pointer;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }
    
    .events-container {
      padding: 20px;
      border-radius: 20px;
      background: #fff;
    }
    
    .events-section {
      margin-bottom: 20px;
      
      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        
        h2 {
          font-size: 24px;
          font-weight: 600;
        }
        
        .view-more {
          color: #6c5ce7;
          text-decoration: none;
          font-weight: 500;
          
          &:hover {
            text-decoration: underline;
          }
        }
      }
      
      h2 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 30px;
      }
    }
    
    .events-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 24px;
    }
    
    .event-card {
      border-radius: 12px;
      background-color: #fff;
      border: 2px solid #a2a2a229;
      overflow: hidden;
      position: relative;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      
      &:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      }
      
      .recording-badge {
        position: absolute;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-self: center;
        top: 10px;
        left: 10px;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        font-size: 12px;
        padding: 4px 8px;
        border-radius: 100px;
        z-index: 1;
        .icon {
          margin-top: 2px;
        }
      }
      
      .event-image {
        height: 180px;
        overflow: hidden;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.5s ease;
        }
      }
      
      &:hover .event-image img {
        transform: scale(1.05);
      }
      
      .event-details {
        padding: 20px;
        
        h3 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 15px;
          color: #333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 44px; 
          line-height: 1.2;
        }
        
        .event-meta {
          margin-bottom: 20px;
          
          .meta-item {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            color: #666;
            
            .icon {
              margin-right: 10px;
            }
          }
        }
        
        .action-button {
          display: block;
          text-align: center;
          padding: 12px;
          border-radius: 6px;
          font-weight: 600;
          text-decoration: none;
          cursor: pointer;
          transition: background-color 0.3s ease;
          
          &.know-more {
            background-color: #7234f7;
            color: white;
          }
          
          &.register-now {
            background-color: #7234f7;
            color: white;
          }
        }
      }
    }
    
    // Media queries for responsiveness
    @media (max-width: 768px) {
      .events-container{
        padding: 10px;
      }
      .hero-section2 {
        padding: 60px 20px;
        border-radius: 0%;
        margin-top: 0px;
        
        .eventHero-content {
          h1 {
            font-size: 36px;
          }
          
          h2 {
            font-size: 28px;
          }
        }
      }
      
      .events-grid {
        grid-template-columns: 1fr;
      }
    }
    
    @media (max-width: 480px) {
      .hero-section2{
        padding: 40px 15px;
        border-radius: 0%;
        margin-top: 0px;
        
        .eventHero-content {
          h1 {
            font-size: 28px;
          }
          
          h2 {
            font-size: 22px;
          }
          
          .search-bar {
            
            input {
              border-radius: 30px;
            }
            
            .search-button {
              border-radius: 30px;
            }
          }
        }
      }
    }
  }