.header-text-1-secondlayer-workshop {
  background: linear-gradient(90deg, #7233f7 0%, #bd89ff 100%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-top: 20px !important;
}
.header-text-2-secondlayer-workshop {
  background: #000 !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-top: 20px !important;
}
.box-wrapper-1-secondlayer-workshop {
  border-radius: 16px !important;
  border: 1px solid #7233f7 !important;
  background: #fff !important;
  padding: 13px;
  gap: 10px;
  align-items: start;
  justify-content: start;
}

.box-wrapper-2-secondlayer-workshop {
  display: grid;
  padding: 10px 30px;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 30px;
}

.text-1-secondlayer-workshop {
  padding: 16px;
  border-radius: 59px;
  background: var(--text, #070512);
  color: #fff !important;
  width: 309px;
  text-align: center;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  position: absolute;
  transform: translate(-50%) rotate(-9.632deg);
}
.text-2-secondlayer-workshop {
  position: absolute;
  border-radius: 59px;
  background: linear-gradient(90deg, #fe7465 0.01%, #7233f7 100.89%);
  display: inline-flex;
  top: 20%;
  transform: translate(-20%) rotate(2.471deg);
  padding: 16px;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  width: 309px;
}

.text-3-secondlayer-workshop {
  position: absolute;
  border-radius: 59px;
  border: 1px solid var(--vibrant, #7234f7);
  display: inline-flex;
  top: 30%;
  transform: translate(-70%) rotate(20.747deg);
  padding: 16px;
  justify-content: center;
  align-items: center;
  color: var(--Filled, #000) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  width: 310px;
  z-index: -1;
}

.text-4-secondlayer-workshop {
  position: absolute;
  border-radius: 59px;
  background: linear-gradient(90deg, #fe7465 0.01%, #7233f7 100.89%);
  display: inline-flex;
  top: 55%;
  transform: translate(-95%) rotate(-9.632deg);
  padding: 16px;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  width: 322px;
}
.text-5-secondlayer-workshop {
  position: absolute;
  border-radius: 59px;
  border: 1px solid var(--vibrant, #7234f7);
  display: inline-flex;
  top: 65%;
  transform: translate(-40%) rotate(-16.871deg);
  padding: 16px;
  justify-content: center;
  align-items: center;
  color: var(--Filled, #000) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  width: 374px;
}
