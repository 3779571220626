.termsPage{
    .temsContent{
        padding: 40px 50px 50px 100px;
        .boldTitle,.desc{
            font-size: 16px;
            font-family: "Outfit", sans-serif;
            word-wrap: break-word;
            line-height: 1.5;
        }
        .boldTitle{
         font-weight: 700;
        }
        .desc{
            font-weight: 300;
        }
    }
    .temsContent>*{
        text-align: justify;
    }
    
  @media (max-width:700px) {
    .temsContent{
        padding: 30px 20px 50px 40px;
    .boldTitle,.desc{
        font-size: 12px;}}
  }
    
}