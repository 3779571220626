.programCardWrapper {
    background-color: #FFFFFF;
    border-radius: 16px;
    display: flex;

    .headerWrapper {
        display: flex;
        width: 100%;


        .course {
            color: #000;
            margin-bottom: 8px;
            font-family: "Outfit", sans-serif;
            font-weight: 500;
            line-height: 20px;
        }

        .institute {
            color: #6A6A6A;
            font-family: "Outfit", sans-serif;
            font-weight: 400;
            margin-bottom: 25px;
        }
    }

    .pointWrapper {
        display: flex;
        margin-bottom: 24px;

        flex-wrap: wrap;
        .tag {
            color: #6A6A6A;

            text-align: justify;
            font-family: "Outfit", sans-serif;
            font-weight: 400;
            padding-left: 4px;
        }

        .value {
            padding-top: 6px;
            font-family: "Outfit", sans-serif;
            color: #000000;
            font-weight: 400;
        }

    }

    .tagsWrapper {
        display: flex;
flex-wrap: wrap;
        .contentBox {
            background-color: #7234F70A;
            border-radius: 50px;

            .tag {
                color: #643ABD;
                font-weight: 400;
                font-family: "Outfit", sans-serif;

            }
        }

    }
    .knowmoreBtn,.shareBtn{
        font-size: 14px;
        font-family: "Outfit", sans-serif;
        box-shadow: none;
        border-radius: 6px;
        font-weight: 400;
        text-transform: none;
    }
    .shareBtn{
       color: #414141;
       border: 1px solid #A4A4A4;
    }
    .knowmoreBtn{
        border: 0.5px solid #7234F7;
        background-color: white;
        color: #7234F7;
    }
}
