.bento-grid-container {
    max-width: 1400px;
    margin: 60px auto;
    
    // Desktop bento grid
    .bento-grid {
      display: grid;
      grid-template-columns: repeat(11, 1fr);
      grid-template-rows: auto auto;
      gap: 20px;
      margin-bottom: 60px;
      
      @media (max-width: 768px) {
        display: none; // Hide on mobile
      }
      
      .bento-item {
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        height: 240px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        
        &:hover {
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        }
        
        &.large {
          grid-column: span 3;
          grid-row: span 3;
          height: 660px;
        }
        
        &.wide {
          grid-column: span 8;
          height: 320px;
        }
        
        &.purple {
          grid-column: span 3;
          background: #7234F7;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          padding: 10px;
          height: 145px;
          text-align: center;
        }
        
        &.student {
          grid-column: span 5;
          grid-row: span 2;
          height: 317px;
        }
        
        &.klu {
          grid-column: span 3;
          height: 152px;
        }
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.5s ease;
        }
        
        &:hover img {
          transform: scale(1.05);
        }
        
        .bento-overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 20px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50.19%, rgba(41, 0, 107, 1) 100%);
          color: white;
          
          h3 {
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            margin: 0;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
          }
        }
        
        .bento-content {
          display: flex;
          align-items: center;
          gap: 10px;
          
          h2 {
            font-size: 64px;
            font-weight: 700;
            margin: 0;
            line-height: 1;
          }
          
          p {
            text-align: left;
            font-size: 18px;
            margin: 0;
          }
        }
      }
    }
    
    // Mobile bento grid
    .bento-grid-mobile {
      display: none;
      flex-direction: column;
      padding: 10px;
      gap: 20px;
      
      @media (max-width: 768px) {
        display: flex;
      }
      
      .bento-item {
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        height: 240px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
        
        &.purple {
          background: #7234F7;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          height: 150px;
          padding: 20px;
          text-align: center;
        }
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        
        .bento-overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 15px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
          color: white;
          
          h3 {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            margin: 0;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
          }
        }
        
        .bento-content {
          display: flex;
          align-items: center;
          gap: 10px;
          
          h2 {
            font-size: 50px !important;
            font-weight: 700;
            margin: 0;
            line-height: 1;
          }
          
          p {
            text-align: left;
            font-size: 16px;
            margin-top: 5px;
          }
        }
      }
    }
}