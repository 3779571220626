.filterDropdown {
    .MuiSelect-root {
        .MuiPaper-root {
          max-height: 200px !important;
          overflow-y: auto;}}
    
    .dropDownWrapper {
        height: 40px;
        width: 200px;
        background-color: rgba(114, 52, 247, 0.04);
        border-radius: 4px;

        .listItem {
            font-family: "Outfit", sans-serif !important;
            font-size: 12px !important;
            font-weight: 400 !important;
        }
                                       
                                       
.MuiListItemText{
    
    font-family: "Outfit", sans-serif !important;
}

        .MuiSelect-root {
            height: 100%;
            padding: 0;
        }
        .MuiBox-root {
            color: #000000 !important;
        }
  
        .MuiOutlinedInput-root {
            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }
       
        .MuiInputBase-input {
            font-size: 14px;
            border: none;
            font-family: "Outfit", sans-serif;
            font-weight: 400;
            color: #000000 !important;
            padding: 8px 12px;
        }
   
    }

}