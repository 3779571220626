.curriculumWrapper {
  text-align: center;
  border-radius: "200px";
  .curriculumContent {
    background-color: rgba(255, 255, 255, 0);
    text-align: end;
    position: relative;
    z-index: 2;

    .contentform {
      text-align: start;

      .curriculumHeader {
        color: #0f002e;
        
        font-family: "Outfit", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
      }

      .curriculumSubHeader {
        color: #5a5a5a;
        text-align: center;
        font-family: "Outfit", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .formDetails {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
      }

      .formInputHeader {
        color: #939393;
        font-family: "Outfit", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 6px;
      }

      .formInput {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 14px;

        .formText {
          width: 100%;
        }

        .formText {
          .MuiFormLabel-root {
            font-size: 14px;
            margin-top: -5px;
          }

          .MuiInputBase-input {
            height: 16px;
            padding: 8px 10px;
            border-radius: 6px;
            border: 1px solid #939393;
            font-size: 14px;
            line-height: normal;
          }

          &.selectForm .MuiInputBase-input {
            padding-top: 10px;
          }
        }
      }

      .contactButton {
        margin-top: 20px;
        height: 40px;
        background-color: #7233f7 !important;
        width: 100%;
        text-transform: none;
        border-radius: 6px;
        padding: 10px;
        font-size: 14px;
        font-family: "Outfit", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .tagline {
        color: #5a5a5a;
        text-align: center;
        font-family: "Outfit", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        padding-top: 10px;

        .terms {
          color: #7234F7;
          font-family: "Outfit", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration: none;
          text-align: center;
        }
      }

      .freeTagline {
        font-family: "Outfit", sans-serif;
        font-weight: 500;
        font-size: 14px;
        padding-top: 10px;

      }
    }
  }
}