.blogArticlesAd {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 13px 20px 10px 20px;
    border-radius: 16px;
    background: linear-gradient(139deg, rgba(78, 232, 235, 0.18) 23.35%, rgba(114, 52, 247, 0.18) 136.91%);
    .blogHeader {
        padding: 5px 10px;
        display: inline-block;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 16px;
        border: 1px solid;
        border-image: linear-gradient(to right, #7234F7, #4EE8EB) 1 / 1;
        font-family: "Outfit", sans-serif;
    }
    .blogAdWrapper {
        display: flex;
        gap: 10px;
        margin-bottom: 12px;
        padding: 12px;
        background-color: #FFFFFF;
        border-radius: 10px;
        .title {
            color: #000;
            font-size: 16px;
            font-family: "Outfit", sans-serif;
            font-weight: 500;
            padding-bottom: 6px;
        }
        .readBlog {
            color: #4A4A4A;
            font-family: "Outfit", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 2px;
            
        }
        .readBlog:hover{
            cursor: pointer;
        }
    }
    .viewMore{
        text-align: center;
        font-family: "Outfit", sans-serif;
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 6px;
    }
    .viewMore:hover{
        cursor: pointer;

    }
}