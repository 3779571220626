.green {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .round1 {
    width: 10px;
    height: 10px;
    background-color: rgb(0, 255, 102); /* Assuming this is the red background you referred to */
    border-radius: 50%;
    position: absolute;
    z-index: 1; /* Lower z-index to stay behind the icon */
  }
  