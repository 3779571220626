// .exploreSection {
//   position: relative;
//   background-color: #ffffff;
//   min-height: 90vh;
//   .App {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     position: relative;
//     top: 0;
//     left: 0;
//     z-index: 1;
//     min-height: inherit !important;
    
//   }

//   .row {
//     display: flex;
//     width: 100%;
//   }

//   .box {
//     width: 35px;
//     height: 35px;
//     border-bottom: 0.723px solid rgba(114, 52, 247, 0.08);
//     border-left: 0.723px solid rgba(114, 52, 247, 0.08);
//     position: relative;
//   }

//   .overlayContainer {
//     position: absolute;
//     margin-top: 100px;
//     top: 0%;
//     width: auto;
//     left: 50%;
//     transform: translate(-50%, 0%);
//     width: 100%;
//     text-align: center;
//     z-index: 2;
    
//   }
//   .mentored {
//     font-size: 30px;
//   }
//   .text1,
//   .text2 {
//     font-family: "Outfit", sans-serif !important;

//   }
//   .text2 {
//     font-weight: 400;
//     align-items: center;
//     justify-content: center;
//     gap: 20px;
//     padding-top: 20px;
//   }
//   .text1 {
//     font-weight: 500;
//   }

//   .btnOverlay {
//     position: relative;
//     z-index: 3; /* Higher than other elements */
//   }

//   .startLearningBtn {
//     box-shadow: none !important;
//     color: #ffffff !important;
//     text-transform: none !important;
//     background-color: #7233f7 !important;
//     padding: 8px 24px !important;
//     font-size: 20px !important;
//     font-family: "Poppins", sans-serif !important;
//     font-weight: 500 !important;
//     z-index: 4 !important; /* Higher than other elements */
//     margin-top: 40px !important;
//   }

//   .box:hover {
//     transition: background-color 0s;
//   }

//   .box.animate {
//     transition: background-color 0.5s;
//   }

//   .hovered {
//     background-color: var(--hover-color);
//   }

//   .slidingText {
//     height: 55px;
//     display: inline-block;
//     overflow: hidden;
//     text-align: left;
//     position: relative;
//   }
//   .slidingText .Story {
//     display: block;
//     font-size: 40px;
//     align-self: center;
//     font-weight: 500;
//     background: linear-gradient(283.5deg, #f88282, #7234f7);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     text-align: right;
//     background-clip: text;
//     animation: anim 8s infinite;
//     color: transparent;
//   }
//   .coursesSlider {
//     width: 100%;
//     overflow: hidden;
//     white-space: nowrap;
//     position: absolute;
//     left: 0px;
//     bottom: 0;
//     z-index: 2;
//     .innerSlider {
//       display: flex;
//       width: 100%;
//       flex-direction: row;
//       /* animation: slide 20s infinite linear; */
//       gap: 20px;
//       padding-bottom: 2rem;
//       &:hover {
//         animation-play-state: paused;
//       }
//     }

//     .paused {
//       animation-play-state: paused !important;
//     }

//     &:hover .innerSlider {
//       animation-play-state: paused;
//     }
//   }
//   @keyframes slide {
//     0% {
//       transform: translateX(0%);
//     }
//     100% {
//       transform: translateX(-100%);
//     }
//   }

//   @keyframes anim {
//     0%,
//     12.5% {
//       transform: translateY(0);
//     }
//     25%,
//     37.5% {
//       transform: translateY(-100%);
//     }
//     50%,
//     62.5% {
//       transform: translateY(-200%);
//     }
//     75%,
//     87.5% {
//       transform: translateY(-300%);
//     }
//     100% {
//       transform: translateY(0);
//     }
//   }

//   @media (max-width: 900px) {
//     .slidingText {
//       height: 40px;
//     }
//     .mentored {
//       vertical-align: super;
//     }
//     .slidingText .Story {
//       font-size: 32px;
//       padding: 0px;
//       text-align: center;
//     }
//     .startLearningBtn {
//       font-size: 16px !important;
//       width: 190px;
//       margin-top: 50px !important;
//       padding: 8px 14px !important;
//       .scrollIcon {
//         height: 36px;
//         width: 36px;
//       }
//     }
//   }
// }
// .exploreSection {
//   position: relative;
//   background-color: #ffffff;
//   min-height: 90vh;
//   padding: 0 !important;
//   margin: 0 !important;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column;
//   height: auto;
//   box-sizing: border-box;

//   .App {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     position: relative;
//     top: 0;
//     left: 0;
//     z-index: 1;
//     min-height: inherit !important;
//   }

//   .row {
//     display: flex;
//     width: 100%;
//   }

//   .box {
//     width: 35px;
//     height: 35px;
//     border-bottom: 0.723px solid rgba(114, 52, 247, 0.08);
//     border-left: 0.723px solid rgba(114, 52, 247, 0.08);
//     position: relative;
//   }

//   .overlayContainer {
//     position: absolute;
//     margin-top: 100px;
//     top: 0%;
//     left: 50%;
//     transform: translate(-50%, 0%);
//     width: 100%;
//     text-align: center;
//     z-index: 2;
//   }

//   .mentored {
//     font-size: 30px;
//   }

//   .text1,
//   .text2 {
//     font-family: "Outfit", sans-serif !important;
//   }

//   .text2 {
//     font-weight: 400;
//     align-items: center;
//     justify-content: center;
//     gap: 20px;
//     padding-top: 20px;
//   }

//   .text1 {
//     font-weight: 500;
//   }

//   .btnOverlay {
//     position: relative;
//     z-index: 3;
//   }

//   .startLearningBtn {
//     box-shadow: none !important;
//     color: #ffffff !important;
//     text-transform: none !important;
//     background-color: #7233f7 !important;
//     padding: 8px 24px !important;
//     font-size: 20px !important;
//     font-family: "Poppins", sans-serif !important;
//     font-weight: 500 !important;
//     z-index: 4 !important;
//     margin-top: 40px !important;
//   }

//   .box:hover {
//     transition: background-color 0s;
//   }

//   .box.animate {
//     transition: background-color 0.5s;
//   }

//   .hovered {
//     background-color: var(--hover-color);
//   }

//   .slidingText {
//     height: 55px;
//     display: inline-block;
//     overflow: hidden;
//     text-align: left;
//     position: relative;
//   }

//   .slidingText .Story {
//     display: block;
//     font-size: 40px;
//     align-self: center;
//     font-weight: 500;
//     background: linear-gradient(283.5deg, #f88282, #7234f7);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     text-align: right;
//     background-clip: text;
//     animation: anim 8s infinite;
//     color: transparent;
//   }

//   .coursesSlider {
//     width: 100%;
//     overflow: hidden;
//     white-space: nowrap;
//     position: absolute;
//     left: 0px;
//     bottom: 0;
//     z-index: 2;

//     .innerSlider {
//       display: flex;
//       width: 100%;
//       flex-direction: row;
//       gap: 20px;
//       padding-bottom: 2rem;
//       &:hover {
//         animation-play-state: paused;
//       }
//     }

//     .paused {
//       animation-play-state: paused !important;
//     }

//     &:hover .innerSlider {
//       animation-play-state: paused;
//     }
//   }

//   @keyframes slide {
//     0% {
//       transform: translateX(0%);
//     }
//     100% {
//       transform: translateX(-100%);
//     }
//   }

//   @keyframes anim {
//     0%,
//     12.5% {
//       transform: translateY(0);
//     }
//     25%,
//     37.5% {
//       transform: translateY(-100%);
//     }
//     50%,
//     62.5% {
//       transform: translateY(-200%);
//     }
//     75%,
//     87.5% {
//       transform: translateY(-300%);
//     }
//     100% {
//       transform: translateY(0);
//     }
//   }

//   /* Mobile view adjustments */
//   @media (max-width: 900px) {
//     min-height: auto;
//     padding-bottom: 0;
//     margin-top: 20px;  /* Move entire section down for mobile */
    
//     /* Move overlayContainer down */
//     .overlayContainer {
//       margin-top: 30px; /* Adjust to push content lower */
//     }

//     .slidingText {
//       height: 40px;
//       margin-top: 30px; /* Move the sliding text down */
//     }

//     .mentored {
//       font-size: 24px;
//       vertical-align: super;
//     }

//     .slidingText .Story {
//       font-size: 32px;
//       padding: 0;
//       text-align: center;
//     }

//     .startLearningBtn {
//       font-size: 16px !important;
//       width: 190px;
//       margin-top: 20px !important; /* Reduce margin-top */
//       padding: 8px 14px !important;
//     }
//   }
// }

// .scrollIcon {
//   height: 36px;
//   width: 36px;
//   animation: scrollUpDown 1.5s infinite ease-in-out;
// }

// @keyframes scrollUpDown {
//   0% {
//     transform: translateY(0);
//   }
//   50% {
//     transform: translateY(-5px); /* Move up by 5px */
//   }
//   100% {
//     transform: translateY(0); /* Move back to original position */
//   }
// }
.exploreSection {
  position: relative;
  background-color: #ffffff;
  min-height: 75vh;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: auto;
  box-sizing: border-box;

  .App {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    min-height: inherit !important;
  }

  .row {
    display: flex;
    width: 100%;
  }

  .box {
    width: 35px;
    height: 35px;
    border-bottom: 0.723px solid rgba(114, 52, 247, 0.08);
    border-left: 0.723px solid rgba(114, 52, 247, 0.08);
    position: relative;
  }

  .overlayContainer {
    position: absolute;
    margin-top: 100px;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    text-align: center;
    z-index: 2;
  }

  .mentored {
    font-size: 30px;
  }

  .text1,
  .text2 {
    font-family: "Outfit", sans-serif !important;
  }

  .text2 {
    font-weight: 400;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding-top: 10px;
   
  }

  .text1 {
    font-weight: 500;
  }

  .btnOverlay {
    position: relative;
    z-index: 3;
  }

  .startLearningBtn {
    box-shadow: none !important;
    color: #ffffff !important;
    text-transform: none !important;
    background-color: #7233f7 !important;
    padding: 8px 24px !important;
    font-size: 20px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500 !important;
    z-index: 4 !important;
    margin-top: 40px !important;
  }

  .box:hover {
    transition: background-color 0s;
  }

  .box.animate {
    transition: background-color 0.5s;
  }

  .hovered {
    background-color: var(--hover-color);
  }

  .slidingText {
    height: 55px;
    display: inline-block;
    overflow: hidden;
    text-align: left;
    position: relative;
  }

  .slidingText .Story {
    display: block;
    font-size: 40px;
    align-self: center;
    font-weight: 500;
    background: linear-gradient(283.5deg, #f88282, #7234f7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: right;
    background-clip: text;
    animation: anim 8s infinite;
    color: transparent;
  }

  .coursesSlider {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
    left: 0px;
    bottom: 0;
    z-index: 2;

    .innerSlider {
      display: flex;
      width: 100%;
      flex-direction: row;
      gap: 20px;
      padding-bottom: 2rem;
      &:hover {
        animation-play-state: paused;
      }
    }

    .paused {
      animation-play-state: paused !important;
    }

    &:hover .innerSlider {
      animation-play-state: paused;
    }
  }

  @keyframes slide {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @keyframes anim {
    0%,
    12.5% {
      transform: translateY(0);
    }
    25%,
    37.5% {
      transform: translateY(-100%);
    }
    50%,
    62.5% {
      transform: translateY(-200%);
    }
    75%,
    87.5% {
      transform: translateY(-300%);
    }
    100% {
      transform: translateY(0);
    }
  }

  /* Mobile view adjustments */
  @media (max-width: 900px) {
    min-height: auto;
    padding-bottom: 0;
    margin-top: 20px;  /* Move entire section down for mobile */
    
    /* Move overlayContainer down */
    .overlayContainer {
      margin-top: 70px; /* Adjust to push content lower */
    }

    .slidingText {
      height: 40px;
      margin-top: 30px; /* Move the sliding text down */
    }

    /* Move text1 and text2 down in mobile view */
    .text1, .text2 {
      margin-top: 1px; /* Adjust this value to push them further down */
    }
    .text2{
      padding-top: 2px;
    }

    .mentored {
      font-size: 24px;
      vertical-align: super;
    }

    .slidingText .Story {
      font-size: 32px;
      padding: 0;
      text-align: center;
    }

    .startLearningBtn {
      font-size: 16px !important;
      width: 190px;
      margin-top: 20px !important; /* Reduce margin-top */
      padding: 8px 14px !important;
    }
  }
}

.scrollIcon {
  height: 36px;
  width: 36px;
  animation: scrollUpDown 1.5s infinite ease-in-out;
}

@keyframes scrollUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* Move up by 5px */
  }
  100% {
    transform: translateY(0); /* Move back to original position */
  }
}
