.navbarWrapper {
  box-shadow: none !important;
  background-color: #ffffff;
  .MuiContainer-root {
    max-width: 100%;
  }
  .MuiTypography-root {
    font-family: "Outfit", sans-serif !important;
  }
  .coursesButton {
    color: #1C1917;
    font-family: "Outfit", sans-serif;
    text-transform: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px;
    display: flex;
    align-items: center;

    &.courseBtnSmall {
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #5f5f5f;
      padding: 4px 16px;
    }
  }
}
.MuiPaper-root {
  box-shadow: none !important;
}
.navbarCourseCategory:hover {
  border-radius: 6px;
  background: rgba(246, 246, 246, 0.8);
  cursor: pointer;
}
.MuiToolbar-root {
  min-height: 48px !important;
}

.drawerTitle {
  color: #1C1917;
  text-align: center;
  font-family: "Outfit", sans-serif !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal !important;
  margin-left: 16px !important;
}

.navLabels {
  &.navSelected {
    color: #ff7262;
  }
}

.dropdown-navbar-desktop {
  position: absolute !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: start !important;
  width: 200px !important;
  border-radius: 8px !important;
  background: #fff !important;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05) !important;
  padding: 10px !important;
  margin-top: 10px !important;
}

.dropdownList-desktop {
  width: 100% !important;
  color: #848484 !important;
  font-family: Outfit !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: none !important;
  justify-content: flex-start !important;
}

.dropdownList-mobile {
  color: var(--Filled, #1C1917) !important;
  font-family: Outfit !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding-left: 25px !important;
}

.dropdownList-desktop:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  color: #1C1917 !important;
}

.dropdownList-mobile:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  color: #1C1917 !important;
}
