body{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}
.bg-white {
    background-color: white;
}
/* Header Section */
.header-section {
    display: flex;
    justify-content: center;
    padding: 12px 32px;
    background-color: white;
    width: 100%;
    margin-bottom: 80px;
}
@media (max-width: 640px) {
    .header-section {
        margin-bottom: 20px;
    }
}
/* Main Content */
.main-content {
    padding: 32px;
    padding-top: 120px;
}
@media (max-width: 640px) {
    .main-content {
        padding: 16px;
        padding-top: 120px;
    }
}
.content-wrapper {
    display: flex;
    justify-content: center;
    gap: 36px;
    // flex-wrap: wrap;
}
@media (max-width: 640px) {
    .content-wrapper {
        flex-direction: column;
        gap: 40px;
    }
}
.text-section {
    display: flex;
    flex-direction: column;
    padding-left: 80px;
    width: 50%;
    font-family: 'Outfit', sans-serif;
}
@media (max-width: 640px) {
    .text-section {
        padding-left: 0;
        width: 100%;
    }
}
.heading {
    font-size: 56px;
    font-weight: bold;
    color: #7234F7;
}
@media (max-width: 640px) {
    .heading {
        font-size: 24px;
    }
}
.description {
    margin-top: 20px;
    font-size: 24px;
    color: #78716C;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
}
@media (max-width: 640px) {
    .description {
        font-size: 14px;
    }
}
.cta-button {
    margin-top: 40px;
    background-color: #7234F7;
    color: white;
    padding: 16px 24px;
    width: 368px;
    border-radius: 8px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    cursor: pointer;
}
@media (max-width: 640px) {
    .cta-button {
        width: 100%;
        padding: 12px;
    }
}
.image-section {
    padding-right: 80px;
    width: 50%;
}
@media (max-width: 640px) {
    .image-section {
        padding-right: 0;
        width: 100%;
    }
}
.avatar-image {
    border-radius: 8px;
    width: 100%;
    height: auto;
}
.recent-avatars-section {
    margin-top: 160px;
    height: 300px;
    text-align: center;
}
@media (max-width: 640px) {
    .recent-avatars-section {
        margin-top: 40px;
    }
}
.section-heading {
    font-size: 34px;
    font-weight: bold;
    font-family: 'Outfit', sans-serif;
}
@media (max-width: 640px) {
    .section-heading {
        font-size: 20px;
    }
}
.subheading {
    margin-top: 12px;
    color: #57534E;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}
@media (max-width: 640px) {
    .subheading {
        font-size: 12px;
    }
}
.avatar-gallery {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 28px;
    margin-top: 56px;
    overflow-x: hidden;
    white-space: nowrap;
    width: 100%;
}
.avatar {
    display: inline-block;
    border-radius: 8px;
    width: 200px;
    height: 200px;
    box-shadow: none;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
    opacity: 0;
}
.avatar:hover {
    transform: translateY(-20px);
    width: 220px;
    height: 220px;
    // box-shadow: 0 3px 10px 3px rgba(0, 0, 0, 0.3);
    z-index: 10;
}
.avatar-gallery .avatar {
    animation: appear 0.7s forwards;
    opacity: 1;
}
@keyframes appear {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@media (max-width: 768px) {
    .avatar-gallery {
        gap: 16px;
        white-space: nowrap;
        animation: scroll 20s linear infinite;
    }
    .avatar {
        width: 150px;
        height: 150px;
        box-shadow: none;
        transition: none;
    }
    .avatar:hover {
        transform: none;
        width: 150px;
        height: 150px;
        box-shadow: none;
    }
}
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}
.prizes-section {
    margin-top: 160px;
    text-align: center;
}
@media (max-width: 640px) {
    .prizes-section {
        margin-top: 40px;
    }
}
.prizes-heading {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Outfit', sans-serif;
}
@media (max-width: 640px) {
    .prizes-heading {
        font-size: 20px;
    }
}
.prizes-content {
    display: flex;
    justify-content: center;
    gap: 36px;
    margin-top: 80px;
}
@media (max-width: 640px) {
    .prizes-content {
        flex-direction: column;
        gap: 30px;
        margin-top: 30px;
    }
}
.prize-img {
    object-fit: cover;
    border-radius: 8px;
    width: 500px;
    height: 500px;
  }
@media (max-width: 640px) {
    .prize-img {
        width: 350px;
        height: 350px;
    }
}
.prizes-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    height: 500px;
}
@media (max-width: 640px) {
    .prizes-list {
        width: 100%;
    }
}
.prize-item {
    display: flex;
    background-color: #8554EF;
    color: white;
    padding: 24px;
    border-radius: 8px;
    text-align: center;
}
.prize-icon {
    margin-right: 20px;
}
.prize-icon2 {
    margin-right: 30px;
}
.prize-title {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    font-family: 'Outfit', sans-serif;
}
@media (max-width: 640px) {
    .prize-title {
        font-size: 12px;
    }
}
.prize-description {
    margin-top: 12px;
    font-size: 16px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
}
@media (max-width: 640px) {
    .prize-description {
        font-size: 10px;
    }
}
/* FAQ Section */
.faq-section {
    margin-top: 120px;
    margin-bottom: 40px;
    text-align: center;
}
@media (max-width: 640px) {
    .faq-section {
        margin-top: 10px;
    }
}
.faq-heading {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Outfit', sans-serif;
}
@media (max-width: 640px) {
    .faq-heading {
        font-size: 20px;
    }
}
.faq-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 36px;
}
.faq-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    padding: 16px;
    cursor: pointer;
}
@media (max-width: 640px) {
    .faq-item {
        font-size: 12px;
    }
}
.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.faq-content {
    display: flex;
    justify-content: left;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    font-size: 16px;
    font-weight: 200;
    margin-top: 8px;
}
@media (max-width: 640px) {
    .faq-content {
        font-size: 10px;
    }
}
.faq-content.open {
    max-height: 200px;
    transition: max-height 0.3s ease;
}
.toggle-icon {
    cursor: pointer;
    transition: transform 0.3s ease;
}
.toggle-icon.rotated {
    transform: rotate(180deg);
}