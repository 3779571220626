.skillsWrapper {
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 16px;
  position: relative;
  .title{
    line-height: 1.5;
    
    font-family: "Outfit", sans-serif;
    font-weight: 700;
  }
  .pointsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .bullet {
      display: flex;
      background-color: #7234f70a;
      align-items: center;
      gap: 15px;
      border-radius: 8px;
      .text {
        font-family: "Outfit", sans-serif;
        word-wrap: break-word;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
      }

      img {
        margin-right: 10px;
      }
    }
  }
}
