.filtersWrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-wrap: wrap;
    margin: 20px 32px 20px 32px;
    background-color: #FFFFFF;
    padding: 24px 18px;
    border-radius: 14px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: -20px;  
        right: -32px; 
        bottom: -20px; 
        left: -32px; 
        background-color: #f3f4fd;
        z-index: -1;
    }

    .filtersSlider {
        display: flex;
        gap: 10px;
        overflow-x: auto;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none; // Internet Explorer 10+
        scrollbar-width: none; // Firefox

        .sliderItem {
            min-width: 200px;
            padding: 0 10px;
        }
    }

    .filtersButtons>* {
        font-size: 14px;
        font-family: "Outfit", sans-serif;
        box-shadow: none;
        border-radius: 6px;
        height: 36px;
        font-weight: 400;
        text-transform: none;
    }

    .filtersButtons {

        justify-content: center;
        gap: 10px;
        align-items: center;
        width: 256px;

        .applyFiltersBtn {
            border: 0.5px solid #7234F7;
            background-color: white;
            color: #7234F7;
            width: 160px !important;
        }

        .clearAllBtn {
            width: 80px !important;
            border: none;
            align-self: center;
            color: #656565;
        }
    }

    .scrollButton {
        background-color: black !important;
        border-radius: 20px !important;
        color: white !important;
        font-size: 20px;
        height: 18px !important;
        min-width: 12px;
        width: 18px !important;
        padding: 4px 6px;
        margin: 5px 10px;
        cursor: pointer;


    }

    .scrollButton[disabled] {
        opacity: 0.2;
        pointer-events: none;
    }

.mobile{
    .applyFiltersBtn{
        border: 1px solid #7234F7;
        background-color: white;
        color: #7234F7;
        width: 180px;
        font-weight: 400;
        padding: 7px 5px;
        border-radius: 18px;
        font-family: "Outfit", sans-serif;
    }
}


}

@media (max-width: 900px) {
    .filtersWrapper {
        justify-content: center;
        position: fixed;
        width: 100%;
        bottom: 0;
        background-color: white;
        z-index: 10;
        margin: 0px;
        padding: 16px;
       
        border-radius: 0;
        button{
            
            color: #7234F7;
            text-transform: none;
        }
    }
    
}