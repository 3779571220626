.mid1-container {
  .section-wrapper {
    position: relative;
    padding: clamp(40px, 5vw, 107px) clamp(16px, 3vw, 34px);

    &.top-wrapper {
      background: var(
        --vc,
        linear-gradient(
          180deg,
          rgba(201, 194, 255, 0) -42.79%,
          rgba(165, 108, 255, 0.08) 100%
        )
      );
    }
  }

  .watermark-mi {
    position: absolute;
    width: clamp(60px, 15vw, 220px);
    height: auto;
    
    &.w1 {
      right: 0;
      top: 0;
    }
    
    &.w2 {
      left: 0;
      top: 0;
    }
  }

  .card {
    display: flex;
    gap: clamp(15px, 2vw, 25px);
    align-items: center;
    
    &.reverse {
      flex-direction: row-reverse;
    }
  }

  .image-container {
    position: relative;
    flex-shrink: 0;
    width: clamp(200px, 40vw, 400px);
  }

  .img-mid {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
  }

  .symbol {
    position: absolute;
    width: clamp(30px, 8vw, 80px);
    height: auto;
    
    &.s1 {
      bottom:  -20px;
      right:  -42px;
    }
    
    &.s2 {
      bottom:  -24px;
      left: -32px;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: clamp(15px, 2vw, 26px);
    flex: 1;
  }

  .keys-container {
    width: 100%;
    padding: 0 clamp(20px, 5vw, 150px);
  }

  .wrapper-key {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: clamp(6px, 1vw, 8px);
    width: 100%;
    margin-bottom: clamp(6px, 1vw, 8px);
  }

  .wrapper-key-inside {
    display: flex;
    align-items: center;
    padding: clamp(4px, 1vw, 8px);
    border: 2px solid #d3cce3;
    border-radius: 8px;
    height: clamp(32px, 5vw, 40px);
    background-color: white;
    
    img {
      width: clamp(12px, 2vw, 14px);
      height: clamp(12px, 2vw, 14px);
      margin-right: clamp(3px, 1vw, 6px);
    }
  }

  .header-text {
    font-family: Outfit !important;
    font-size: clamp(16px, 4vw, 36px) !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;
    text-align: center;
    color: #fe7465;
  }

  .normal-text {
    font-family: Poppins !important;
    font-size: clamp(9px, 2vw, 12px) !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    text-align: center;
    padding: 50px;
    
    &.bold {
      font-weight: 700 !important;
    }
  }

  @media (max-width: 768px) {
    .normal-text {
      font-size: clamp(8px, 3vw, 11px) !important;
      padding: 20px;
    }
  }
  

  .normal-text-key {
    font-family: Poppins !important;
    font-size: clamp(12px, 1.5vw, 15px) !important;
    font-weight: 400 !important;
    line-height: 1.2 !important;
    color: #000;
  }

  @media (max-width: 768px) {
    .normal-text-key {
      font-size: clamp(10px, 3vw, 11px) !important;
    }
  }

  @media (max-width: 900px) {
    .card {
      flex-direction: column-reverse !important;
      
      &.reverse {
        flex-direction: column-reverse !important;
      }
    }

    .image-container {
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
    }

    .keys-container {
      padding: 0;
    }
  }
}