.header-last {
  color: #fe7465 !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.normal-last {
  color: var(--Filled, #000) !important;
  text-align: center !important;
  font-family: Outfit !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-top: 20px !important;
  width: 80vw !important;
}

.wrapper-last {
  padding: 40px 10px !important;
  padding-bottom: 150px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.btn-last {
  margin-top: 40px !important;
  width: 234px !important;
  border-radius: 6px !important;
  background: #7234f7 !important;
  color: white !important;
  padding: 10px 36px !important;
}

.btn-last:hover {
  background-color: #5a1d9b !important;
}

@media (max-width: 900px) {
  .header-last {
    font-size: 24px !important;
  }
  .normal-last {
    font-size: 20px !important;
  }
}
