.applicationSection {
  overflow: hidden;
 
  .contentWrapper {
    background-color: #100031;
    border-radius: 16px;
    display: flex;
    position: relative;
    
    .leftSection {
      .titlem {
        line-height: 1;
        font-weight: 500;
      }
      .label {
        font-weight: 400;
        align-self: center;
      }
      .label,
      .titlem {
        font-family: "Outfit", sans-serif;
        word-wrap: break-word;
        color: #ffffff;
      }
    }

    .rightSection {
      display: flex;
      justify-content: center;
      .cardWrapper {
        background-color: #ffffff;
        border-radius: 10px 10px 10px 10px;
        overflow: hidden;
        margin-bottom: 12px !important;
        padding-bottom: 14px;
        
        .title {
          font-family: "Outfit", sans-serif;
          word-wrap: break-word;
          color: #ffffff;
          font-weight: 500;
          
        }

        .courseDuration {
          display: flex;
          min-width: 124px !important;
          gap: 12px;
          padding: 7px 15px 7px 12px;
          background-color: #ffffff;
          border-radius: 6px;
          align-items: center;
          align-self: flex-start;
          // margin: 18px 0px 23px 0px;
          .label2 {
            font-family: "Poppins", sans-serif;
            word-wrap: break-word;
            font-size: 12px;
            font-weight: 500;
          }
        }
        .textlabel {
          color: #ffffff;
          font-family: "Outfit", sans-serif;
          word-wrap: break-word;
          font-weight: 400;
          font-size: 14px;
          align-self: center;
        }
        .cost {
          font-family: "Outfit", sans-serif;
          font-weight: 500;
          font-size: 24px;
          padding: 20px 0px 15px 0px;
          
        }
        .deadline {
          color: #404040;
          font-family: "Outfit", sans-serif;
          font-weight: 500;
          padding: 23px 0px 43px 0px;
          word-wrap: break-word;
          font-size: 14px;
        }
        .startBtn {
          padding: 11px 35px;
          width: 100%;
          text-transform: none;
          font-size: 20px;
          color: #ffffff;
          border-radius: 10px;
          background-color: #772bd8;
        }
        .strikethrough{
          position: relative;
          display: inline-block;
        }
        .strikethrough::before {
          content: "";
          position: absolute;
          width: 80%;
          height: 1px;
          background-color: black;
          top: 50%;
          left: 10%;
          transform: rotate(-35deg);
          transform-origin: center;
        }
      }
    }
  }
}

.imgWrapper{
  border-radius: 5px 5px 0 0;
}
@media (max-width: 600px) {

  .title{
    font-size: 15px !important;
    margin-top: -2px !important;
    margin-bottom: 30px !important;
    margin-right: 5px !important;
  }
  .contentWrapper {
    padding: 16px;
    margin: 0;
  
  }
  

  .label2{
    font-size: 9px !important;
    margin-left: 2px !important;
    
  }
 
  
  .cardWrapper {
    max-width: 100%; // Ensure full width on mobile
    padding: 16px 8px; // Adjust padding for mobile view
  }

  .leftSection .title {
    font-size: 24px; // Adjust title font size for mobile
    margin-bottom: 15px;
    text-align: center;
  }
  .imgWrapper{
    border-radius: 5px;
  }
  .startBtn{
    font-size: 15px !important;
  }
  .cost {
    font-size: 20px;
    text-align: center;
  }
  .courseDuration{
    margin-right: 0px;
  }
  @media (max-width: 600px) {
    .courseDuration {
      margin-top: 22px;
      margin-left: 0px !important; // Increase space for smaller screens
    }
  
    .label2 {
      font-size: 10px !important; // Slightly smaller font for mobile
      margin-top: 15px; // Add more spacing for better clarity
    }
  
    .title{
      font-size: 20px !important;
      margin-top: 4px !important;
      margin-left: 0px !important;
      line-height: 0.2;
      margin-bottom: -10px !important;
    }
  }
  
}

