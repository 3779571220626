.refundPolicy {
  .refundPolicyContent {
    padding: 40px 50px 50px 100px;
    
    .boldTitle,
    .desc,.bold {
      font-family: "Outfit", sans-serif;
      word-wrap: break-word;
      line-height: 1.5;
    }
    .boldTitle {
      font-weight: 700;

      font-size: 18px;
    }
    .desc {
      font-weight: 300;
      font-size: 16px;
    }
    .bold {
      font-weight: 600;
      font-size: 16px;
    }
  }
  .refundPolicyContent >*{
    text-align: justify;
  }
  @media (max-width: 700px) {
    .refundPolicyContent {
      padding: 30px 20px 50px 40px;
      .bold,
      .desc {
        font-size: 12px;
      }
      .boldTitle {
        font-size: 14px;
      }
    }
  }
}
