.questionsSection {
    background-color: #000;
    .title {
      font-family: "poppins", sans-serif;
      word-wrap: break-word;
      line-height: 60px;
      font-weight: 500;
      text-align: center;
      color: #BD89FF;
    }
  
    .MuiAccordionSummary-content {
      margin: 0 0 9px 0 !important;
    }
  
    // Remove default Accordion background color on expand
    :global(.MuiAccordion-root::before) {
      display: none !important;
    }
  }

  
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;

  &.fade-up-active {
    opacity: 1;
    transform: translateY(0);
    animation: fadeUp 1s ease forwards;
  }
}

  