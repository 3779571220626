/* Base Styles */
.container {
    font-family: Poppins;
    margin: 32px;
    padding: 50px 80px;
    border-radius: 20px;
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;
    justify-content: center;
    flex-shrink: 0;
}

.head-container {
    font-family: Poppins;
    display: flex;
    gap: 80px;
}

.first-left {
    flex: 3;
}

.first-right {
    flex: 2;
}

.chip{
    display: inline-flex;
    padding: 6px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 200px;
    border: 1px solid var(--Neautral-400, #D6D3D1);
    color: var(--Neautral-700, #57534E);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 10px;
}

.ptitle{
    color: var(--Neautral-900, #1C1917);
    text-align: center;
    font-family: Outfit;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.pusers{
    color: #171717;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.users {
    display: inline-flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: var(--radius-radius-md, 8px);
    flex-shrink: 0;
}
.user-avatar{
    width: var(--Icon-icon-md, 20px);
    height: var(--Icon-icon-md, 20px);
    border-radius: 20px;
}

.hcon{
    margin-bottom: 20px;
}

.overview-features {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 140px;
    flex-shrink: 0;
    border-radius: var(--radius-radius-md, 8px);
    background: #FBFBFB;
    margin-bottom: 40px;
}

.ofi {
    display: flex;
    color: var(--Neautral-800, #292524);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}
.ofim{
    margin-right: 10px;
}

.ofb {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.pot {
    align-self: stretch;
    color: var(--Neautral-900, #1C1917);
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
}

.overview-text {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
}

.poo {
    align-self: stretch;
    color: var(--Neautral-900, #1C1917);
    text-align: justify;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
}

.dft {
    margin-top: 60px;
    align-self: stretch;
    color: var(--Neautral-900, #1C1917);
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
    margin-bottom: 20px;
}

.difficulty {
    display: flex;
    width: 160px;
    padding: 3px var(--Spacing-space-7, 12px);
    justify-content: center;
    align-items: center;
    color: #1C1917;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 6px;
    border-radius: 4px;
    border: 0.5px solid #6B6B6B;
    background: rgba(244, 244, 244, 0.10);
}

.pbutton{
    display: flex;
    width: 100%;
    padding: 16px 83px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--radius-radius-md, 8px);
    background: #7234F7;
    border-color: #7234F7;
    margin-bottom: 40px;
    color: #FFF;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    cursor: pointer;
}

.sst {
    color: var(--Neautral-900, #1C1917);
    text-align: justify;
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}

/* Skills Section */
.skills-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    gap: 10px;
}

.skills-section {
    padding-top: 40px;
}

.sli {
    display: inline-flex;
    padding: 8px 16px;
    flex-basis: auto;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    opacity: 0.5;
    background: var(--Colors-bronze-200, #E7E5E4);
    color: var(--Neautral-900, #1C1917);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
}

.mst {
    margin-top: 30px;
    align-self: stretch;
    color: var(--Neautral-900, #1C1917);
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
    margin-bottom: 10px;
}

.m_name{
    color: var(--Neautral-900, #1C1917);
    text-align: justify;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
}

.m_des {
    align-self: stretch;
    color: var(--Neautral-900, #1C1917);
    text-align: justify;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    margin-bottom: 14px;
}

.m_mdes{
    align-self: stretch;
    color: var(--Neautral-700, #57534E);
    text-align: justify;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
}

.mid-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.midd{
    color: var(--Neautral-900, #1C1917);
    text-align: justify;
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 48px */
    margin-bottom: 30px;
}

.recommended-projects {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.rpt {
    color: var(--Neautral-900, #1C1917);
    text-align: justify;
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 48px */
    margin-top: 20px;
}

.projects-grid {
    margin-top: 40px;
    display: flex;
    gap: 24px;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.proj {
    border: 1px solid #B8B8B8;
    border-radius: 10px;
    width: 450px; 
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Media Queries */
@media (min-width: 768px) {
    .head-container {
        flex-direction: row;
    }
    .projects-grid {
        flex-direction: row;
    }
    .ptitle{
        font-size: 32px;
        text-align: justify ;
    }
}

@media (max-width: 768px) {
    .container {
        padding: 20px;
        margin: 10px;
    }

    .pbutton {
        padding: 10px 20px;
    }

    .projects-grid {
        flex-direction: column;
        gap: 16px;
    }

    .chip {
        padding: 4px 8px;
    }

    .skills-list {
        gap: 5px;
    }
}

@media (max-width: 480px) {
    .container {
        margin: 10px;
        padding: 15px 20px;
    }
    .pbutton {
        padding: 8px 16px;
    }

    .proj {
        padding: 10px;
        max-width: 320px;
    }

    .skills-list {
        gap: 5px;
    }

    .chip {
        font-size: 12px;
    }
}


@media (max-width: 768px) {
    .head-container {
        flex-direction: column; /* Set it as column for mobile */
        gap: 20px; /* Adjust the gap to a smaller value */
    }

    .first-left, .first-right {
        flex: unset; /* Remove the flex values */
        width: 100%; /* Make them take full width */
    }

    .container {
        padding: 20px;
        margin: 10px;
    }

    .pbutton {
        padding: 10px 20px;
    }

    .projects-grid {
        flex-direction: column;
        gap: 16px;
    }

    .chip {
        padding: 4px 8px;
    }

    .skills-list {
        gap: 5px;
    }
}
