.courseOutcomes {
    .title{
        font-family: "Outfit", sans-serif;
        word-wrap: break-word;
        font-weight: 500;
        line-height: 60px;
        text-align: center;
        margin-bottom: 10px !important;
    }
  .cardsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    .card {
      // border: 1px solid #bbbbbb;
      border-radius: 20px;
      background-color: #FFFFFF;
      .text1 {
        font-family: "Outfit", sans-serif;
        word-wrap: break-word;
        font-weight: 700;
        line-height: 1.5;
      }
      .text2 {
        font-family: "Poppins", sans-serif;
        word-wrap: break-word;
        font-size: 14px;
        font-weight: 400;
        color: #797979;
      }
    }
  }
}
