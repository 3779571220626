.exploreAdWrapper {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 400px;
    position: relative;
    width: 100%;
    align-items: flex-end;

    .exploreContentOverlay {
        height: 45%;
        width: 100%;
        background-color: rgb(255 255 255 / 85%);
        filter: blur(25px);

    }

    .exploreContent {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 0;
        padding: 40px 16px;
        left: 0;

        .text1,
        .text2,
        .text3,
        .expBtn {
            font-family: "Outfit", sans-serif;

            color: #000;
            line-height: 20px;
        }

        .text1 {
            font-size: 22px;
            font-weight: 400;
        }

        .text2,
        .text3 {
            font-size: 14px;
            font-weight: 300;

        }

        .expBtn {
            font-size: 14px;
            text-transform: none;
            color: #FFFFFF;
            border-radius: 6px;
            background-color: #7234F7;
            margin-top: 25px;

        }
    }
}